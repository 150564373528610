import { Input, Select } from "../../../../../components/Input/input.styles";
import { Title } from "../../../../../components/Title/title.styles";
import {
  Column,
  FlexBox,
  FormGroup,
  MultipleFormGroupItems,
} from "../../../../../components/Utils/flexbox.styles";
import { PrimaryButton } from "../../../../../components/Buttons/buttons.styles";
import { MarkdownEditor } from "../../../../../components/Markdown/markdown-editor";
import { ManageRecipesAddSubProduct } from "./product-add";
import type {
  OptionType,
  ValidationErrors,
} from "../../../../../types/definitions";
import { useCallback, useState } from "react";
import { useRecipesStore } from "../../../../../store/recipes";
import type { ZodIssue } from "zod";
import { z, ZodError } from "zod";
import { Loader } from "../../../../../components/Utils/loader";
import {
  ErrorMessages,
  SuccessMessage,
} from "../../../../../components/Alerts/alerts";
import { ManageRecipesAddType } from "./recipe-types-add";

export interface IProductStep {
  quantity: number;
  unity: string;
  selectedSubProduct: OptionType;
}

export default function ManageRecipeAdd() {
  const [selectedProducts, setSelectedProducts] = useState<IProductStep[]>([]);

  const { successMsg, createRecipe, setSuccessMsg, errorMsg } =
    useRecipesStore();

  const [title, setTitle] = useState<string>("");

  const [cookingTime, setCookingTime] = useState<number>(0);

  const [difficulty, setDifficulty] = useState<number>(0);

  const [content, setContent] = useState<string>("");

  const [errorsList, setErrorsList] = useState<ValidationErrors>({});

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectedType, setSelectedType] = useState<number>();

  const onSubmit = useCallback(async () => {
    setErrorsList({});
    setSuccessMsg("");
    const validation = z.object({
      title: z.string().min(1, {
        message: "Le nom de la recette doit contenir au moins 1 caractère.",
      }),
      content: z.string().min(1, {
        message: "Le contenu de la recette doit contenir au moins 1 caractère.",
      }),
      cookingTime: z.number().positive({
        message: "Le temps de préparation doit être un nombre positif.",
      }),
      difficulty: z.number().min(0, {
        message: "La difficulté doit être un nombre positif.",
      }),
      selectedType: z
        .number({
          message: "Le champs type de recette est requis.",
        })
        .min(0, {
          message: "Le champs type de recette est requis.",
        }),
    });

    try {
      const result = validation.parse({
        title,
        content,
        cookingTime,
        difficulty,
        selectedType,
      });
      setIsLoading(true);
      await createRecipe({ ...result, selectedProducts });
      setTitle("");
      setCookingTime(0);
      setDifficulty(0);
      setSelectedProducts([]);
    } catch (error) {
      if (error instanceof ZodError) {
        setErrorsList(
          (error as ZodError).errors.reduce(
            (a, value: ZodIssue) => ({
              ...a,
              [value.path[0]]: value.message,
            }),
            {},
          ),
        );
      } else {
        setErrorsList({ default: "Une erreur inattendue est survenue." });
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    content,
    cookingTime,
    createRecipe,
    difficulty,
    selectedProducts,
    selectedType,
    setSuccessMsg,
    title,
  ]);

  const handleNewType = (option: OptionType) => {
    if (option.value && parseInt(option.value)) {
      setSelectedType(parseInt(option.value));
    }
  };

  return (
    <Column>
      <Title>Ajouter une nouvelle recette</Title>
      <Column>
        {successMsg && <SuccessMessage successMsg={successMsg} />}
        {errorsList && <ErrorMessages errorsList={errorsList} />}
        {errorMsg && <ErrorMessages errorsList={errorMsg} />}
        <FormGroup>
          <span>Titre de la recette:</span>
          <Input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Gateau au chocolat"
          />
        </FormGroup>
        <MultipleFormGroupItems>
          <FormGroup>
            <span>Temps de préparation (en minutes):</span>
            <Input
              value={cookingTime}
              onChange={(e) => setCookingTime(parseInt(e.target.value))}
              placeholder="5"
            />
          </FormGroup>
          <FormGroup>
            <span>Difficulté de la recette:</span>
            <Select
              value={difficulty}
              onChange={(e) => setDifficulty(parseInt(e.target.value))}
            >
              <option value={0}>Facile</option>
              <option value={1}>Moyen</option>
              <option value={2}>Difficile</option>
            </Select>
          </FormGroup>
        </MultipleFormGroupItems>
        <FormGroup>
          <span>Type de recette</span>
          <ManageRecipesAddType handleNewType={handleNewType} />
        </FormGroup>
        <ManageRecipesAddSubProduct
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
        />
        <Column>
          <span>Contenue de la recette</span>
          <MarkdownEditor onChange={setContent} value={content} />
        </Column>
        <FlexBox className="centered">
          <PrimaryButton onClick={onSubmit} disabled={isLoading}>
            {isLoading ? <Loader state={isLoading} /> : "Valider"}
          </PrimaryButton>
        </FlexBox>
      </Column>
    </Column>
  );
}
