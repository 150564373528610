import styled from "styled-components";

export const Label = styled.div<{ $fontSize?: string }>`
  padding: 5px 10px;
  margin: none;
  border-radius: 20px;
  color: white;
  background-color: grey;
  font-size: ${(props) => (props.$fontSize ? props.$fontSize : "inherit")};
`;

export const GreenLabel = styled(Label)`
  background-color: #118c32;
`;

export const RedLabel = styled(Label)`
  background-color: #c33e4f;
`;
