import { create } from "zustand";
import { API_BASE_URL } from ".";
import type { RecipeVersion, RecipeVersionsStore } from "./types";
import { type APIResponse } from "../types/definitions";
import { normalizeError } from "../helpers/normalize-error";
import { isRight, left, right } from "fp-ts/lib/Either";
import apiClient from "../axios";
import type { IProductStep } from "../pages/panel/sections/ManageRecipes/add/add";

export interface ICreateRecipeVersionParams {
  title: string;
  id?: number;
  content: string;
  selectedProducts: IProductStep[];
}

export function fetchRecipeVersions(id: string): APIResponse<RecipeVersion[]> {
  return apiClient
    .get(`${API_BASE_URL}/recipes/versions/${id}`)
    .then((result) => right(result.data))
    .catch((err) => left(normalizeError(err)));
}

export function createRecipeVersion(
  params: ICreateRecipeVersionParams,
): Promise<APIResponse<undefined>> {
  return apiClient
    .post(`${API_BASE_URL}/recipes/versions`, {
      Title: params.title,
      ID: params.id,
      Content: params.content,
      SelectedProducts: [
        ...params.selectedProducts.map((product) => {
          return {
            Quantity: product.quantity,
            Unity: product.unity,
            SelectedSubProduct: {
              Value: product.selectedSubProduct.value,
              Label: product.selectedSubProduct.label,
            },
          };
        }),
      ],
    })
    .then((result) => {
      if (result.data && result.data.Success) {
        return right(result);
      } else {
        return left(normalizeError(result));
      }
    })
    .catch((err) => {
      return left(normalizeError(err));
    }) as any;
}

export const useRecipesVersionsStore = create<RecipeVersionsStore>()((set) => ({
  recipeVersions: [],
  errorMsg: "",
  successMsg: "",
  loadRecipeVersions: async (id: string) => {
    const response = await fetchRecipeVersions(id);
    if (isRight(response)) {
      return set((state) => ({
        ...state,
        recipeVersions: response.right.Data ?? [],
      }));
    }

    return set((state) => ({
      ...state,
      errorMsg: response.left.message,
      successMsg: "",
    }));
  },
  setSuccessMsg: (content: string) => {
    return set((state) => ({ ...state, successMsg: content }));
  },
  createRecipeVersion: async (params: ICreateRecipeVersionParams) => {
    const response = await createRecipeVersion(params);

    if (isRight(response)) {
      return set((state) => ({
        ...state,
        errorMsg: "",
        successMsg:
          "Une nouvelle version de la recette a été ajouté avec succès.",
      }));
    }

    return set((state) => ({
      ...state,
      errorMsg: response.left.message,
      successMsg: "",
    }));
  },
}));
