import AsyncSelect from "react-select/async";
import {
  DarkAsynSelectStyle,
  Input,
} from "../../../../../components/Input/input.styles";
import axios from "axios";
import { debounce, uniq } from "lodash";
import { useRef, useEffect, useState, useCallback } from "react";
import { API_BASE_URL } from "../../../../../store";
import { DEFAULT_PAGE_SIZE } from "../../../../../utils/constants";
import type {
  OptionType,
  ValidationErrors,
} from "../../../../../types/definitions";
import {
  DangerButton,
  DarkButton,
} from "../../../../../components/Buttons/buttons.styles";
import { Table } from "../../../../../components/Table/table.styles";
import { Column } from "../../../../../components/Utils/flexbox.styles";
import type { ZodIssue } from "zod";
import { z, ZodError } from "zod";
import type { IProductStep } from "./add";
interface IManageAllergiesAddSubProductsProps {
  selectedProducts: IProductStep[];
  setSelectedProducts: any;
}

export function ManageRecipesAddSubProduct(
  props: IManageAllergiesAddSubProductsProps,
) {
  const cancelTokenSource = useRef<ReturnType<
    typeof axios.CancelToken.source
  > | null>(null);

  const [selectedSubProduct, setSelectedSubProduct] = useState<OptionType>();

  const [errorsLists, setErrorsLists] = useState<ValidationErrors>();

  const [quantity, setQuantity] = useState<number>(0);

  const [unity, setUnity] = useState<string>();

  const loadOptions = async (inputValue: string) => {
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel("Cancelling previous request");
    }

    cancelTokenSource.current = axios.CancelToken.source();

    try {
      const response = await axios.get(`${API_BASE_URL}/ingredients`, {
        params: {
          PageSize: DEFAULT_PAGE_SIZE,
          PageNumber: 1,
          Name: inputValue,
        },
        cancelToken: cancelTokenSource.current.token, // Pass the cancel token
      });

      if (response.data) {
        return response.data.Data.data.map((product: any) => ({
          label: product.Name,
          value: product.ID,
        }));
      }
      return [];
    } catch (error) {
      return []; // Return empty options if there is an error or cancellation
    }
  };

  const handleNewProduct = (input: OptionType | null) => {
    if (input !== null) {
      setSelectedSubProduct(input);
    }
  };

  const addNewProductStep = useCallback(async () => {
    setErrorsLists({});
    const validation = z.object({
      quantity: z.number().positive({
        message: "La quantité doit être un chiffre positif.",
      }),
      unity: z.string().min(1, {
        message: "L'unité doit au moins contenir 1 caractère.",
      }),
      selectedSubProduct: z
        .object({
          label: z.string(),
          value: z.string(),
        })
        .required(),
    });

    try {
      const result = validation.parse({
        quantity,
        unity,
        selectedSubProduct: {
          ...selectedSubProduct,
          value: selectedSubProduct?.value.toString(),
        },
      });
      setQuantity(0);
      setUnity("");
      props.setSelectedProducts((prev: IProductStep[]) =>
        uniq([...prev, result]),
      );
    } catch (error) {
      if (error instanceof ZodError) {
        setErrorsLists(
          (error as ZodError).errors.reduce(
            (a, value: ZodIssue) => ({
              ...a,
              [value.path[0]]: value.message,
            }),
            {},
          ),
        );
      } else {
        setErrorsLists({ default: "Une erreur inattendue est survenue." });
      }
    }
  }, [props, quantity, selectedSubProduct, unity]);

  const removeProduct = (value: string, unity: string, quantity: number) => {
    props.setSelectedProducts((prev: IProductStep[]) =>
      prev.filter(
        (p) =>
          p.selectedSubProduct.value !== value ||
          p.quantity !== quantity ||
          p.unity !== unity,
      ),
    );
  };

  const debouncedLoadOptions = useRef(
    debounce(
      (inputValue: string, callback: (options: OptionType[]) => void) => {
        loadOptions(inputValue).then(callback);
      },
      500,
    ),
  ).current;

  // Cleanup on unmount to cancel any pending request
  useEffect(() => {
    return () => {
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel("Component unmounted");
      }
    };
  }, []);

  const [inputValue, setInputValue] = useState("");

  return (
    <Column>
      <span>Liste des ingrédiens:</span>
      <Table>
        <thead>
          <tr>
            <th>Ingrédient</th>
            <th>Quantité</th>
            <th>Unité</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody>
          {props.selectedProducts.map((product) => (
            <tr
              key={`${product.selectedSubProduct.value}-${product.unity}-${product.quantity}`}
            >
              <td>{product.selectedSubProduct.label}</td>
              <td>{product.quantity}</td>
              <td>{product.unity}</td>
              <td>
                <DangerButton
                  onClick={() =>
                    removeProduct(
                      product.selectedSubProduct.value,
                      product.unity,
                      product.quantity,
                    )
                  }
                >
                  Supprimer
                </DangerButton>
              </td>
            </tr>
          ))}
          <tr>
            <td>
              <AsyncSelect
                cacheOptions
                styles={DarkAsynSelectStyle(false)}
                loadOptions={debouncedLoadOptions}
                onInputChange={setInputValue}
                onChange={(newVal) => handleNewProduct(newVal)}
                placeholder="Rechercher un ingrédient"
                defaultOptions
                noOptionsMessage={() => `Aucun résultat pour "${inputValue}"`}
                loadingMessage={() => "Chargement en cours..."}
              />
            </td>
            <td>
              <Input
                isError={errorsLists && errorsLists["quantity"] !== undefined}
                style={{ width: "100%" }}
                value={quantity}
                onChange={(e) => setQuantity(parseInt(e.target.value))}
                placeholder="3"
              />
            </td>
            <td>
              <Input
                style={{ width: "100%" }}
                value={unity}
                onChange={(e) => setUnity(e.target.value)}
                isError={errorsLists && errorsLists["unity"] !== undefined}
                placeholder="g"
              />
            </td>
            <td>
              <DarkButton onClick={addNewProductStep}>Valider</DarkButton>
            </td>
          </tr>
        </tbody>
      </Table>
    </Column>
  );
}
