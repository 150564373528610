import styled from "styled-components";

export const Pane = styled.div`
  background-color: #2b2a2f;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  min-width: 300px;
  width: auto;

  .title {
    font-size: 14px;
    color: #8a8895;
    margin: 0;
  }

  .figure {
    font-size: 26px;
    margin: 0;
    color: white;
    font-family: "GothamPro", sans-serif;
  }

  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
`;
