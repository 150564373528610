import get from "lodash/get";

function isObjectWithMessage(error: unknown): error is { Message: string } {
  return typeof get(error, "Message", null) === "string";
}

function isServerError(error: unknown): error is { data: { Message: string } } {
  return typeof get(error, "data.Message", null) === "string";
}

function isServerScopedError(
  error: unknown,
): error is { data: { Error: { Message: string } } } {
  return typeof get(error, "data.Error.Message", null) === "string";
}

function isServerMalformedError(
  error: unknown,
): error is { data: { Error: string } } {
  return typeof get(error, "data.Error", null) === "string";
}

export function normalizeError(error: unknown): Error {
  if (error instanceof Error) {
    return error;
  }

  if (typeof error === "string") {
    return new Error(error);
  }

  if (isObjectWithMessage(error)) {
    return new Error(error.Message);
  }

  if (isServerError(error)) {
    return new Error(error.data.Message);
  }

  if (isServerMalformedError(error)) {
    return new Error(error.data.Error);
  }

  if (isServerScopedError(error)) {
    return new Error(error.data.Error.Message);
  }

  return Error("Something went wrong.");
}
