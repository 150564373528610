import { useState, useCallback } from "react";
import type { ZodIssue } from "zod";
import { z, ZodError } from "zod";
import {
  DangerButton,
  PrimaryButton,
} from "../../../../../components/Buttons/buttons.styles";
import { Input } from "../../../../../components/Input/input.styles";
import { Title } from "../../../../../components/Title/title.styles";
import {
  Column,
  FormGroup,
  FlexBox,
} from "../../../../../components/Utils/flexbox.styles";
import { useAllergiesStore } from "../../../../../store/allergies";
import type {
  OptionType,
  ValidationErrors,
} from "../../../../../types/definitions";
import { Loader } from "../../../../../components/Utils/loader";
import {
  ErrorMessages,
  SuccessMessage,
} from "../../../../../components/Alerts/alerts";
import { Table } from "../../../../../components/Table/table.styles";
import { ManageAllergiesAddSubProduct } from "./product-add";
import { uniq } from "lodash";

export default function ManageAllergiesAdd() {
  const [allergyName, setAllergyName] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorsList, setErrorsList] = useState<ValidationErrors>({});
  const [selectedProducts, setSelectedProducts] = useState<OptionType[]>([]);

  const { errorMsg, createAllergy, successMsg, setSuccessMsg } =
    useAllergiesStore();

  const onSubmit = useCallback(async () => {
    setErrorsList({});
    setSuccessMsg("");
    const validation = z.object({
      name: z.string().min(1, {
        message: "Le nom de l'allergie doit contenir au moins 1 caractère.",
      }),
    });

    try {
      const result = validation.parse({ name: allergyName });
      setIsLoading(true);
      await createAllergy({
        ...result,
        productsList: selectedProducts.map((product) => product.value),
      });
      setAllergyName("");
      setSelectedProducts([]);
    } catch (error) {
      if (error instanceof ZodError) {
        setErrorsList(
          (error as ZodError).errors.reduce(
            (a, value: ZodIssue) => ({
              ...a,
              [value.path[0]]: value.message,
            }),
            {},
          ),
        );
      } else {
        setErrorsList({ default: "Une erreur inattendue est survenue." });
      }
    } finally {
      setIsLoading(false);
    }
  }, [allergyName, createAllergy, selectedProducts, setSuccessMsg]);

  const handleNewProduct = (input: OptionType) => {
    setSelectedProducts((prev) => uniq([...prev, input]));
  };

  const removeProduct = (value: string) => {
    setSelectedProducts((prev) => prev.filter((p) => p.value !== value));
  };

  return (
    <Column>
      <Title>Ajouter une nouvelle allergie</Title>
      {Object.keys(errorsList).length > 0 && (
        <ErrorMessages errorsList={errorsList} />
      )}
      {errorMsg && <ErrorMessages errorsList={errorMsg} />}
      {successMsg && <SuccessMessage successMsg={successMsg} />}
      <Column>
        <FormGroup>
          <span>Nom du lallergie:</span>
          <Input
            value={allergyName}
            onChange={(e) => setAllergyName(e.target.value)}
            isError={Boolean(errorsList?.name)}
            placeholder="Pollene"
          />
        </FormGroup>
        <FormGroup>
          <span>Liste des aliments interdis:</span>
          <Table>
            <thead>
              <tr>
                <th>Ingrédient</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {selectedProducts.map((product) => (
                <tr key={product.value}>
                  <td>{product.label}</td>
                  <td>
                    <DangerButton onClick={() => removeProduct(product.value)}>
                      Supprimer
                    </DangerButton>
                  </td>
                </tr>
              ))}
              <ManageAllergiesAddSubProduct onChange={handleNewProduct} />
            </tbody>
          </Table>
        </FormGroup>
        <FlexBox className="centered">
          <PrimaryButton onClick={onSubmit} disabled={isLoading}>
            {isLoading ? <Loader state={isLoading} /> : "Valider"}
          </PrimaryButton>
        </FlexBox>
      </Column>
    </Column>
  );
}
