import { Icon } from "@iconify/react";
import type { ValidationErrors } from "../../types/definitions";
import { ErrorAlert, SuccessAlert } from "./alerts.styles";

interface ErrorMessagesProps {
  errorsList: ValidationErrors | string; // Can be an object or a string
}

export const ErrorMessages = ({ errorsList }: ErrorMessagesProps) => {
  if (typeof errorsList === "string") {
    return (
      <ErrorAlert>
        <span>
          <Icon icon="ic:twotone-error" />
          {errorsList}
        </span>
      </ErrorAlert>
    );
  }

  if (typeof errorsList === "object" && Object.keys(errorsList).length > 0) {
    return (
      <ErrorAlert>
        <span>
          <Icon icon="ic:twotone-error" />
          <p>
            Merci de bien vouloir corriger les erreurs suivantes:
            <ul>
              {Object.values(errorsList).map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          </p>
        </span>
      </ErrorAlert>
    );
  }

  return null; // No error to display
};

export const SuccessMessage = ({ successMsg }: { successMsg: string }) => (
  <SuccessAlert>
    <span>
      <Icon icon="mdi:success" />
      {successMsg}
    </span>
  </SuccessAlert>
);
