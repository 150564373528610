import { create } from "zustand";
import { API_BASE_URL } from ".";
import type { Recipe, RecipeContent, RecipeStore } from "./types";
import type { PaginatedAPIResponse, Pagination } from "../types/definitions";
import { type APIResponse } from "../types/definitions";
import { normalizeError } from "../helpers/normalize-error";
import { isRight, left, right } from "fp-ts/lib/Either";
import apiClient from "../axios";
import type { IProductStep } from "../pages/panel/sections/ManageRecipes/add/add";
import { DEFAULT_PAGE_SIZE } from "../utils/constants";

export interface IFetchRecipesParams extends Pagination {
  name?: string;
}

export interface ICreateRecipeParams {
  title: string;
  content: string;
  cookingTime: number;
  difficulty: number;
  selectedProducts: IProductStep[];
  selectedType: number;
}

export function fetchRecipes({
  pageNumber,
  pageSize,
  name,
}: IFetchRecipesParams): PaginatedAPIResponse<Array<Recipe>> {
  const params: Record<string, any> | URLSearchParams | undefined = {
    PageSize: pageSize,
    PageNumber: pageNumber,
  };

  if (name !== undefined && name !== "") {
    params.Name = name;
  }
  return apiClient
    .get(`${API_BASE_URL}/recipes`, { params })
    .then((result) => right(result.data))
    .catch((err) => left(normalizeError(err)));
}

export function fetchRecipe(id: string): APIResponse<Recipe> {
  return apiClient
    .get(`${API_BASE_URL}/recipes/${id}`)
    .then((result) => right(result.data))
    .catch((err) => left(normalizeError(err)));
}

export function fetchRecipeContent(id: string): APIResponse<RecipeContent> {
  return apiClient
    .get(`${API_BASE_URL}/recipe-files/${id}`)
    .then((result) => right(result.data))
    .catch((err) => left(normalizeError(err)));
}

export function createRecipe(
  params: ICreateRecipeParams,
): Promise<APIResponse<undefined>> {
  return apiClient
    .post(`${API_BASE_URL}/recipes`, {
      Title: params.title,
      Content: params.content,
      CookingTime: params.cookingTime,
      Difficulty: params.difficulty,
      SelectedType: params.selectedType,
      SelectedProducts: [
        ...params.selectedProducts.map((product) => {
          return {
            Quantity: product.quantity,
            Unity: product.unity,
            SelectedSubProduct: {
              Value: product.selectedSubProduct.value,
              Label: product.selectedSubProduct.label,
            },
          };
        }),
      ],
    })
    .then((result) => {
      if (result.data && result.data.Success) {
        return right(result);
      } else {
        return left(normalizeError(result));
      }
    })
    .catch((err) => {
      return left(normalizeError(err));
    }) as any;
}

export const useRecipesStore = create<RecipeStore>()((set) => ({
  recipes: [],
  recipeVersions: [],
  errorMsg: "",
  successMsg: "",
  pageSize: DEFAULT_PAGE_SIZE,
  totalPages: 0,
  totalRecords: 0,
  fetchRecipeContent: async (id: string) => {
    const response = await fetchRecipeContent(id);
    if (isRight(response)) {
      return set((state) => ({
        ...state,
        recipeContent: response.right.Data ?? [],
      }));
    }

    return set((state) => ({
      ...state,
      errorMsg: response.left.message,
      successMsg: "",
    }));
  },
  loadRecipes: async (params: IFetchRecipesParams) => {
    const response = await fetchRecipes(params);
    if (isRight(response)) {
      return set((state) => ({
        ...state,
        recipes: response.right.Data.data ?? [],
        errorMsg: "",
        pageSize: response.right.Data.pageSize,
        totalPages: response.right.Data.totalPages,
        totalRecords: response.right.Data.totalRecords,
      }));
    }

    return set((state) => ({
      ...state,
      errorMsg: response.left.message,
      successMsg: "",
    }));
  },
  loadRecipe: async (id: string) => {
    const response = await fetchRecipe(id);
    if (isRight(response)) {
      return set((state) => ({
        ...state,
        selectedRecipe: response.right.Data ?? [],
      }));
    }

    return set((state) => ({
      ...state,
      errorMsg: response.left.message,
      successMsg: "",
    }));
  },
  setSuccessMsg: (content: string) => {
    return set((state) => ({ ...state, successMsg: content }));
  },
  createRecipe: async (params: ICreateRecipeParams) => {
    const response = await createRecipe(params);

    if (isRight(response)) {
      return set((state) => ({
        ...state,
        errorMsg: "",
        successMsg: "Recette ajoutée avec succès.",
      }));
    }

    return set((state) => ({
      ...state,
      errorMsg: response.left.message,
      successMsg: "",
    }));
  },
}));
