import { create } from "zustand";
import { API_BASE_URL } from ".";
import type { Treatment, TreatmentStore } from "./types";
import axios from "axios";
import type {
  APIResponse,
  PaginatedAPIResponse,
  Pagination,
} from "../types/definitions";
import { normalizeError } from "../helpers/normalize-error";
import { isRight, left, right } from "fp-ts/lib/Either";
import { DEFAULT_PAGE_SIZE } from "../utils/constants";

export interface IFetchTreatmentsParams extends Pagination {
  name?: string;
}

export interface ICreateTreatmentParams {
  name: string;
}

export function fetchTreatments({
  pageNumber,
  pageSize,
  name,
}: IFetchTreatmentsParams): PaginatedAPIResponse<Array<Treatment>> {
  const params: Record<string, any> | URLSearchParams | undefined = {
    PageSize: pageSize,
    PageNumber: pageNumber,
  };

  if (name !== undefined && name !== "") {
    params.Name = name;
  }
  return axios
    .get(`${API_BASE_URL}/treatments`, { params })
    .then((result) => right(result.data))
    .catch((err) => left(normalizeError(err)));
}

export function fetchTreatment(id: string): APIResponse<Treatment> {
  return axios
    .get(`${API_BASE_URL}/treatments/${id}`)
    .then((result) => right(result.data))
    .catch((err) => left(normalizeError(err)));
}

export function createTreatment({
  name,
}: ICreateTreatmentParams): Promise<APIResponse<undefined>> {
  return axios
    .post(`${API_BASE_URL}/treatments`, { name })
    .then((result) => {
      if (result.data && result.data.Success) {
        return right(result);
      } else {
        return left(normalizeError(result));
      }
    })
    .catch((err) => {
      return left(normalizeError(err));
    }) as any;
}

export const useTreatmentsStore = create<TreatmentStore>()((set) => ({
  treatments: [],
  errorMsg: "",
  successMsg: "",
  pageSize: DEFAULT_PAGE_SIZE,
  totalPages: 0,
  totalRecords: 0,
  loadTreatments: async (params: IFetchTreatmentsParams) => {
    const response = await fetchTreatments(params);
    if (isRight(response)) {
      return set((state) => ({
        ...state,
        treatments: response.right.Data.data ?? [],
        pageSize: response.right.Data.pageSize,
        totalPages: response.right.Data.totalPages,
        totalRecords: response.right.Data.totalRecords,
        errorMsg: "",
      }));
    }

    return set((state) => ({ ...state, errorMsg: response.left.message }));
  },
  loadTreatment: async (id: string) => {
    const response = await fetchTreatment(id);
    if (isRight(response)) {
      return set((state) => ({
        ...state,
        selectedTreatment: response.right.Data,
        errorMsg: "",
      }));
    }

    return set((state) => ({ ...state, errorMsg: response.left.message }));
  },
  createTreatment: async (params: ICreateTreatmentParams) => {
    const response = await createTreatment(params);

    if (isRight(response)) {
      return set((state) => ({
        ...state,
        errorMsg: "",
        successMsg: "Traitement ajouté avec succès.",
      }));
    }

    return set((state) => ({
      ...state,
      errorMsg: response.left.message,
      successMsg: "",
    }));
  },
  setSuccessMsg: (content) => {
    return set((state) => ({
      ...state,
      errorMsg: "",
      successMsg: content,
    }));
  },
}));
