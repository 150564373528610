import { create } from "zustand";
import { API_BASE_URL } from ".";
import type { RecipeType, RecipeTypeStore } from "./types";
import type { PaginatedAPIResponse, Pagination } from "../types/definitions";
import { type APIResponse } from "../types/definitions";
import { normalizeError } from "../helpers/normalize-error";
import { isRight, left, right } from "fp-ts/lib/Either";
import apiClient from "../axios";
import axios from "axios";
import { DEFAULT_PAGE_SIZE } from "../utils/constants";

export interface IFetchRecipeTypesParams extends Pagination {
  name?: string;
}

export interface ICreateRecipeTypeParams {
  name: string;
}

export function createRecipeType({
  name,
}: ICreateRecipeTypeParams): Promise<APIResponse<undefined>> {
  return axios
    .post(`${API_BASE_URL}/recipe-types`, { name })
    .then((result) => {
      if (result.data && result.data.Success) {
        return right(result);
      } else {
        return left(normalizeError(result));
      }
    })
    .catch((err) => {
      return left(normalizeError(err));
    }) as any;
}

export function fetchRecipeTypes({
  pageNumber,
  pageSize,
  name,
}: IFetchRecipeTypesParams): PaginatedAPIResponse<Array<RecipeType>> {
  const params: Record<string, any> | URLSearchParams | undefined = {
    PageSize: pageSize,
    PageNumber: pageNumber,
  };

  if (name !== undefined && name !== "") {
    params.Name = name;
  }
  return apiClient
    .get(`${API_BASE_URL}/recipe-types`, { params })
    .then((result) => right(result.data))
    .catch((err) => left(normalizeError(err)));
}

export function fetchRecipeType(id: string): APIResponse<RecipeType> {
  return axios
    .get(`${API_BASE_URL}/recipe-types/${id}`)
    .then((result) => right(result.data))
    .catch((err) => left(normalizeError(err)));
}

export const useRecipeTypesStore = create<RecipeTypeStore>()((set) => ({
  types: [],
  errorMsg: "",
  successMsg: "",
  totalRecords: 0,
  pageSize: DEFAULT_PAGE_SIZE,
  totalPages: 0,
  loadRecipeTypes: async (params: IFetchRecipeTypesParams) => {
    const response = await fetchRecipeTypes(params);
    if (isRight(response)) {
      return set((state) => ({
        ...state,
        types: response.right.Data.data ?? [],
        pageSize: response.right.Data.pageSize,
        totalRecords: response.right.Data.totalRecords,
        totalPages: response.right.Data.totalPages,
        errorMsg: "",
      }));
    }

    return set((state) => ({ ...state, errorMsg: response.left.message }));
  },
  loadRecipeType: async (id: string) => {
    const response = await fetchRecipeType(id);
    if (isRight(response)) {
      return set((state) => ({
        ...state,
        selectedRecipeType: response.right.Data,
        errorMsg: "",
      }));
    }

    return set((state) => ({ ...state, errorMsg: response.left.message }));
  },
  createRecipeType: async (params: ICreateRecipeTypeParams) => {
    const response = await createRecipeType(params);

    if (isRight(response)) {
      return set((state) => ({
        ...state,
        errorMsg: "",
        successMsg: "Le nouveau type de recette a été ajouté avec succès.",
      }));
    }

    return set((state) => ({
      ...state,
      errorMsg: response.left.message,
      successMsg: "",
    }));
  },
}));
