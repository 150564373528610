import styled from "styled-components";

interface InputProps {
  isError?: boolean;
}

export const Input = styled.input<InputProps>`
  padding: 6px;
  border: none;
  background-color: #313236;
  color: lightgray;
  border-radius: 3px;

  outline: ${(props) =>
    props.isError ? "1px solid rgba(232,5,5, 0.7)" : "none"};

  &:focus {
    outline: 1px solid rgba(123, 91, 198, 0.7);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  &.min {
    width: auto;
  }
`;

export const Select = styled.select`
  padding: 6px;
  border: none;
  background-color: #313236;
  color: lightgray;
  border-radius: 3px;
`;

export const TextArea = styled.textarea`
  padding: 6px;
  border: 1px solid grey;
  border-radius: 5px;
  background-color: #313236;
  color: lightgray;
  border-radius: 3px;
`;

export const InputIconWrapper = styled.div`
  display: flex;
  height: 100%;
  .icon {
    height: 100%;
    padding: 5px;
    background: #292a2d;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  input {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
`;

export const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #313236;
  border: 1px solid grey;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  z-index: 1000;
`;

export const DropdownItem = styled.div`
  padding: 6px;
  color: lightgray;
  cursor: pointer;

  &:hover {
    background-color: #444;
  }
`;

export const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

export const DarkAsynSelectStyle = (isError: boolean) => ({
  container: (provided: any) => ({
    ...provided,
    width: "100%",
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: "#313236",
    color: "lightgray",
    border: isError ? "1px solid red" : "none",
    borderRadius: "3px",
    padding: "6px",
    boxShadow: state.isFocused ? "rgba(0, 0, 0, 0.24) 0px 3px 8px" : "none",
    outline: state.isFocused ? "1px solid rgba(123, 91, 198, 0.7)" : "none",
    width: "100%",
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: "#313236",
    borderRadius: "3px",
    border: "1px solid grey",
    zIndex: 1000,
    width: "100%",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    padding: "6px",
    backgroundColor: state.isFocused ? "#444" : "#313236",
    color: "lightgray",
    cursor: "pointer",
    width: "100%",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "lightgray",
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    color: "lightgray",
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: "lightgray",
  }),
});
