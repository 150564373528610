import { Title } from "../../components/Title/title.styles";
import { Column } from "../../components/Utils/flexbox.styles";

export default function Error404() {
  return (
    <Column className="full centered" style={{ gap: "5px" }}>
      <Title>
        <span>Erreur 404</span>
      </Title>
      <p style={{ padding: 0, margin: 0 }}>
        La page que vous avez demandé est introuvable.
      </p>
    </Column>
  );
}
