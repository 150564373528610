import { create } from "zustand";
import { API_BASE_URL } from ".";
import type { Product, ProductStore } from "./types";
import axios from "axios";
import type { PaginatedAPIResponse, Pagination } from "../types/definitions";
import { type APIResponse } from "../types/definitions";
import { normalizeError } from "../helpers/normalize-error";
import { isRight, left, right } from "fp-ts/lib/Either";
import { DEFAULT_PAGE_SIZE } from "../utils/constants";

export interface IFetchProductsParams extends Pagination {
  name?: string;
}

export interface ICreateProductParams {
  name: string;
  description: string;
}

export function fetchProducts({
  pageNumber,
  pageSize,
  name,
}: IFetchProductsParams): PaginatedAPIResponse<Array<Product>> {
  const params: Record<string, any> | URLSearchParams | undefined = {
    PageSize: pageSize,
    PageNumber: pageNumber,
  };

  if (name !== undefined && name !== "") {
    params.Name = name;
  }
  return axios
    .get(`${API_BASE_URL}/ingredients`, { params })
    .then((result) => right(result.data))
    .catch((err) => left(normalizeError(err)));
}

export function fetchProduct(id: string): APIResponse<Product> {
  return axios
    .get(`${API_BASE_URL}/ingredients/${id}`)
    .then((result) => right(result.data))
    .catch((err) => left(normalizeError(err)));
}

export function createProduct({
  name,
  description,
}: ICreateProductParams): Promise<APIResponse<undefined>> {
  return axios
    .post(`${API_BASE_URL}/ingredients`, { name, description })
    .then((result) => {
      if (result.data && result.data.Success) {
        return right(result);
      } else {
        return left(normalizeError(result));
      }
    })
    .catch((err) => {
      return left(normalizeError(err));
    }) as any;
}

export const useProductsStore = create<ProductStore>()((set) => ({
  products: [],
  errorMsg: "",
  successMsg: "",
  totalRecords: 0,
  pageSize: DEFAULT_PAGE_SIZE,
  totalPages: 0,
  loadProduct: async (id: string) => {
    const response = await fetchProduct(id);
    if (isRight(response)) {
      return set((state) => ({
        ...state,
        selectedProduct: response.right.Data,
        errorMsg: "",
      }));
    }

    return set((state) => ({ ...state, errorMsg: response.left.message }));
  },
  loadProducts: async (params: IFetchProductsParams) => {
    const response = await fetchProducts(params);
    if (isRight(response)) {
      return set((state) => ({
        ...state,
        pageSize: response.right.Data.pageSize,
        totalRecords: response.right.Data.totalRecords,
        totalPages: response.right.Data.totalPages,
        products: response.right.Data.data ?? [],
        errorMsg: "",
      }));
    }

    return set((state) => ({ ...state, errorMsg: response.left.message }));
  },
  createProduct: async (params: ICreateProductParams) => {
    const response = await createProduct(params);

    if (isRight(response)) {
      return set((state) => ({
        ...state,
        errorMsg: "",
        successMsg: "L'ingrédient a été ajouté avec succès.",
      }));
    }

    return set((state) => ({
      ...state,
      errorMsg: response.left.message,
      successMsg: "",
    }));
  },
  setSuccessMsg: (content: string) => {
    return set((state) => ({
      ...state,
      errorMsg: "",
      successMsg: content,
    }));
  },
}));
