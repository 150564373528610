import styled from "styled-components";
import { media_device } from "../../utils/media-device";

export const SidebarWrapper = styled.div`
  background-color: var(--onc-black);
  color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  overflow-x: hidden;

  border-right: 2px solid var(--onc-grey);

  .head {
    display: none;
  }

  @media ${media_device.laptop} {
    .head {
      display: flex;
      border-bottom: 2px solid var(--onc-grey);
      align-items: center;
      justify-content: center;
      padding-top: 20px;
      padding-bottom: 30px;
    }
  }

  .footer {
    color: rgba(60, 60, 60, 0.9);
    font-size: 14px;
    padding: 0 10px 20px 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: none;
    }

    @media ${media_device.laptop} {
      span {
        display: inline;
      }
    }
  }
`;

export const SideNav = styled.ul`
  list-style: none;
  font-size: 15px;
  padding-left: 15px;
  li {
    padding-left: 5px;
  }

  p {
    display: none;
  }

  @media ${media_device.laptop} {
    p {
      text-transform: uppercase;
      font-family: "GothamPro", sans-serif;
      font-size: 13px;
      padding: 0;
      margin: 10px 0 3px 0;
      display: inline;
    }
  }
`;

export const SidebarLink = styled.a`
  display: flex;
  align-items: center;
  gap: 5px;
  text-decoration: none;
  cursor: pointer;
  padding: 8px;
  margin-right: 8px;
  border-radius: 10px;
  transition: 0.3s;
  &:hover,
  &.active {
    background-color: rgba(255, 255, 255, 0.2);
  }

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;

  span {
    display: none;
  }

  @media ${media_device.laptop} {
    span {
      display: inline;
    }
  }
`;
