import { Icon } from "@iconify/react";
import {
  InfoAlert,
  ErrorAlert,
} from "../../../../../components/Alerts/alerts.styles";
import { PrimaryButton } from "../../../../../components/Buttons/buttons.styles";
import {
  Input,
  InputIconWrapper,
} from "../../../../../components/Input/input.styles";
import { Pagination } from "../../../../../components/Pagination/pagination";
import { Table } from "../../../../../components/Table/table.styles";
import { Title } from "../../../../../components/Title/title.styles";
import { FlexBox } from "../../../../../components/Utils/flexbox.styles";
import { Loader } from "../../../../../components/Utils/loader";
import { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecipesStore } from "../../../../../store/recipes";
import _debounce from "lodash/debounce";
import { DEFAULT_PAGE_SIZE } from "../../../../../utils/constants";

export function ManageRecipesList() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { loadRecipes, recipes, errorMsg, totalPages } = useRecipesStore();

  const [currentPage, setCurrentPage] = useState<number>(1);

  const [searchInput, setSearchInput] = useState("");

  const fetchRecipes = useCallback(
    (nameInput: string) => {
      setIsLoading(true);
      loadRecipes({
        name: nameInput,
        pageNumber: currentPage,
        pageSize: DEFAULT_PAGE_SIZE,
      }).then(() => setIsLoading(false));
    },
    [currentPage, loadRecipes],
  );

  useEffect(() => {
    fetchRecipes(searchInput);
  }, [fetchRecipes, searchInput]);

  const debounceFn = useCallback(
    _debounce((nameInput: string) => fetchRecipes(nameInput), 300),
    [fetchRecipes],
  );

  const handleSearchInput = (newInput: string) => {
    setSearchInput(newInput);
    debounceFn(newInput);
  };

  return (
    <>
      <Title>Gestion des recettes</Title>
      <InfoAlert>
        <span>
          <Icon icon="ic:twotone-info" />
          Vous pouvez au travers de ce panel gérer l&apos;ensemble des
          utilisateurs inscris sur OncAdvice.
        </span>
      </InfoAlert>
      <FlexBox className="aligned">
        <InputIconWrapper>
          <div className="icon">
            <Icon icon="ph:magnifying-glass-duotone" />
          </div>
          <Input
            value={searchInput}
            onChange={(e) => handleSearchInput(e.target.value)}
            placeholder="Rechercher par nom"
          />
        </InputIconWrapper>
        <PrimaryButton onClick={() => navigate("/manage-recipes/add")}>
          Ajouter une recette
        </PrimaryButton>
      </FlexBox>
      {errorMsg.length > 0 && (
        <ErrorAlert>
          <span>
            <Icon icon="ic:twotone-error" />
            {errorMsg}
          </span>
        </ErrorAlert>
      )}
      <Table>
        <thead>
          <tr>
            <th>#</th>
            <th>Nom</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={3}>
                <Loader state={isLoading} />
              </td>
            </tr>
          ) : (
            <>
              {recipes && recipes.length > 0 ? (
                recipes.map((recipe, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{recipe.Name}</td>
                    <td>
                      <FlexBox className="horizontal-aligned">
                        <PrimaryButton
                          onClick={() =>
                            navigate(`/manage-recipes/details/${recipe.ID}`)
                          }
                        >
                          Voir
                        </PrimaryButton>
                      </FlexBox>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td style={{ textAlign: "center" }} colSpan={3}>
                    Aucun résultat
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </Table>
      <Pagination
        totalPages={totalPages}
        page={currentPage}
        onChange={setCurrentPage}
      />
    </>
  );
}
