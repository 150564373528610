import { Route, Routes } from "react-router-dom";
import Error404 from "../../../errors/404";
import ManageUsersIndex from ".";
import { ManageUsersDetails } from "./details";

export default function ManageUsersRooter() {
  return (
    <Routes>
      <Route path="" element={<ManageUsersIndex />} />
      <Route path="/details/*" element={<ManageUsersDetails />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
}
