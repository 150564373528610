import { useNavigate, useParams } from "react-router-dom";
import {
  Column,
  FlexBox,
} from "../../../../../components/Utils/flexbox.styles";
import { useEffect, useState } from "react";
import { SubTitle, Title } from "../../../../../components/Title/title.styles";
import { Pane } from "../../../../../components/Pane/pane.styles";
import { Loader } from "../../../../../components/Utils/loader";
import { Table } from "../../../../../components/Table/table.styles";
import {
  DarkButton,
  PrimaryButton,
} from "../../../../../components/Buttons/buttons.styles";
import { useRecipesVersionsStore } from "../../../../../store/recipes-versions";

export function ManageRecipesListVersions() {
  const { loadRecipeVersions, recipeVersions } = useRecipesVersionsStore();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      loadRecipeVersions(id).then(() => {
        setIsLoading(false);
      });
    }
  }, []);

  return (
    <Column>
      <FlexBox className="aligned">
        <Title>Versions</Title>
        <PrimaryButton
          onClick={() => navigate(`/manage-recipes/versions/${id}/add`)}
        >
          Ajouter
        </PrimaryButton>
      </FlexBox>

      {isLoading ? (
        <Loader state={isLoading} />
      ) : (
        <>
          {recipeVersions.map((version) => (
            <Pane key={version.Name}>
              <SubTitle>
                Version {version.VersionNumber} - {version.Name}
              </SubTitle>
              <Table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Nom de lingrédient</th>
                    <th>Quantité</th>
                    <th>Unité</th>
                    <th>Contenu</th>
                  </tr>
                </thead>
                <tbody>
                  {version.RecipeCompositions?.map((composition, index) => (
                    <tr key={composition.ID}>
                      <td>{index + 1}</td>
                      <td>{composition.Ingredient?.Name}</td>
                      <td>{composition.Quantity}</td>
                      <td>{composition.Unit?.Name}</td>
                      <td>
                        <DarkButton
                          onClick={() =>
                            navigate(
                              `/manage-recipes/details/content/${version.Recipe?.Text}`,
                            )
                          }
                        >
                          Voir
                        </DarkButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Pane>
          ))}
        </>
      )}
    </Column>
  );
}
