import { Route, Routes } from "react-router-dom";
import ManageTreatmentsIndex from ".";
import { ManageTreatmentsAdd } from "./add";
import Error404 from "../../../errors/404";
import { ManageTreatmentsDetails } from "./details";

export default function ManageTreatmentsRooter() {
  return (
    <Routes>
      <Route path="" element={<ManageTreatmentsIndex />} />
      <Route path="add" element={<ManageTreatmentsAdd />} />
      <Route path="/details/:id" element={<ManageTreatmentsDetails />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
}
