import styled from "styled-components";

export const Alert = styled.div`
  color: white;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  padding: 1rem;
  margin: 1rem 0;

  span {
    display: flex;
    align-items: center;
    font-size: 13px;
  }

  svg {
    margin-right: 5px;
    font-size: 24px;
  }
`;

export const InfoAlert = styled(Alert)`
  background: rgba(40, 116, 166, 0.5);
  border-left: #2874a6 4px solid;

  svg {
    color: #2874a6;
  }
`;

export const ErrorAlert = styled(Alert)`
  background: rgba(135, 0, 0, 0.5);
  border-left: #870000 4px solid;

  svg {
    color: #dc0000;
  }
`;

export const SuccessAlert = styled(Alert)`
  background: rgba(146, 189, 142, 0.5);
  border-left: #92bd8e 4px solid;
  svg {
    color: #92bd8e;
  }
`;

export const WarningAlert = styled(Alert)`
  background: rgba(241, 196, 15, 0.5);
  border-left: #f1c40f 4px solid;
  svg {
    color: #f1c40f;
  }
`;
