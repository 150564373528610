import type { STAGE } from "../types/definitions";

export const API_BASE_URL: string = (function () {
  switch (process.env.REACT_APP_STAGE as STAGE) {
    case "dev":
      return "http://localhost:8080";
    case "beta":
      return "https://beta.api.oncadvice.fr";
    case "prod":
      return "https://api.oncadvice.fr";
    default:
      return "http://localhost:8080";
  }
})();

export const LOCAL_STORAGE_KEY = "oncadvice-storage";
