import { Column } from "../../../../../components/Utils/flexbox.styles";
import { ManageRecipesList } from "./list-recipes";
import { ManageRecipesTypesList } from "./types/recipes-types";

export default function ManageRecipesIndex() {
  return (
    <Column>
      <ManageRecipesList />
      <ManageRecipesTypesList />
    </Column>
  );
}
