import styled from "styled-components";

export const Title = styled.h1`
  font-family: "GothamBook", sans-serif;
  font-size: 26px;
  font-weight: bolder;
  color: white;
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const SubTitle = styled(Title)`
  font-size: 22px;
`;
