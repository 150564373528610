import { useCallback, useState } from "react";
import {
  DarkButton,
  PrimaryButton,
} from "../../components/Buttons/buttons.styles";
import Icons from "../../components/Icons/icons";
import { Input } from "../../components/Input/input.styles";
import { Title } from "../../components/Title/title.styles";
import { Column, FlexBox } from "../../components/Utils/flexbox.styles";
import { LoginForm, LoginWrapper } from "./login.styles";
import type { ZodIssue } from "zod";
import { z, ZodError } from "zod";
import { Loader } from "../../components/Utils/loader";
import type { ValidationErrors } from "../../types/definitions";
import { SHA256 } from "crypto-js";
import { useAuthenticationStore } from "../../store/authentication";
import { ErrorMessages, SuccessMessage } from "../../components/Alerts/alerts";
import { WarningAlert } from "../../components/Alerts/alerts.styles";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const [errorsList, setErrorsList] = useState<ValidationErrors>({});

  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const { authenticate, errorMsg, successMsg, token, clearAuthentication } =
    useAuthenticationStore();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = useCallback(async () => {
    setErrorsList({});
    const validation = z.object({
      email: z.string().email({
        message: "L'email saisis est invalide.",
      }),
      password: z.string().min(1, {
        message: "Le mot de passe doit au moins contenir 1 caractère.",
      }),
    });

    try {
      const result = validation.parse({ email, password });
      const encryptedPassword = SHA256(result.password).toString();
      setIsLoading(true);
      authenticate({ email: result.email, password: encryptedPassword });
    } catch (error) {
      if (error instanceof ZodError) {
        setErrorsList(
          (error as ZodError).errors.reduce(
            (a, value: ZodIssue) => ({
              ...a,
              [value.path[0]]: value.message,
            }),
            {},
          ),
        );
      } else {
        setErrorsList({ default: "Une erreur inattendue est survenue." });
      }
    } finally {
      setIsLoading(false);
    }
  }, [authenticate, email, password]);

  return (
    <LoginWrapper>
      <LoginForm>
        <Column style={{ height: "100%" }}>
          <FlexBox className="centered" style={{ height: "auto" }}>
            <Icons name="logo" />
          </FlexBox>
          <Title>Connexion</Title>
          {!token || token === "" ? (
            <Column style={{ height: "100%", flexGrow: "1" }}>
              {errorMsg && <ErrorMessages errorsList={errorMsg} />}
              {successMsg && successMsg.length > 0 ? (
                <SuccessMessage successMsg={successMsg} />
              ) : (
                <>
                  <Column style={{ gap: "5px" }}>
                    <span style={{ margin: 0, padding: 0 }}>Email</span>
                    <Input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      isError={errorsList && errorsList["email"] !== undefined}
                      placeholder="joedoe@gmail.com"
                    />
                  </Column>
                  <Column style={{ gap: "5px", flexGrow: 1 }}>
                    <span style={{ margin: 0, padding: 0 }}>Mot de passe</span>
                    <Input
                      value={password}
                      isError={
                        errorsList && errorsList["password"] !== undefined
                      }
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      placeholder="*******"
                    />
                  </Column>
                  <PrimaryButton onClick={onSubmit} disabled={isLoading}>
                    {isLoading ? <Loader state={isLoading} /> : "Se connecter"}
                  </PrimaryButton>
                </>
              )}
            </Column>
          ) : (
            <Column>
              <WarningAlert>
                <span>Il semblerait que vous êtes déjà authentifié.</span>
              </WarningAlert>
              <DarkButton onClick={() => navigate("/")}>
                Accèder au panel
              </DarkButton>
              <PrimaryButton onClick={clearAuthentication}>
                Se déconnecter
              </PrimaryButton>
            </Column>
          )}
        </Column>
      </LoginForm>
    </LoginWrapper>
  );
}
