import { GreenLabel, RedLabel } from "../Label/label.styles";
import { FlexBox } from "../Utils/flexbox.styles";
import { Pane } from "./pane.styles";

interface AnalyticPaneProps {
  title: string;
  figure: string;
  evolution?: number;
}

interface RenderEvolutionProps {
  evolution?: number;
}

function RenderEvolution(props: RenderEvolutionProps) {
  if (props.evolution) {
    if (props.evolution >= 0) {
      return <GreenLabel $fontSize="12px">+{props.evolution}%</GreenLabel>;
    } else {
      return <RedLabel $fontSize="12px">{props.evolution}%</RedLabel>;
    }
  }

  return <></>;
}

export default function AnalyticPane(props: AnalyticPaneProps) {
  return (
    <Pane>
      <p className="title">{props.title}</p>
      <FlexBox className="aligned">
        <p className="figure">{props.figure}</p>
        <RenderEvolution evolution={props.evolution} />
      </FlexBox>
    </Pane>
  );
}
