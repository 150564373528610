import { create } from "zustand";
import { API_BASE_URL, LOCAL_STORAGE_KEY } from ".";
import type { AuthenticationStore, MinimalUser } from "./types";
import axios from "axios";
import { type APIResponse } from "../types/definitions";
import { normalizeError } from "../helpers/normalize-error";
import { isRight, left, right } from "fp-ts/lib/Either";
import { persist } from "zustand/middleware";

export interface IAuthentication {
  email: string;
  password: string;
}

interface IAuthenticationResponse {
  Token: string;
  User: MinimalUser;
}

export function authenticate({
  email,
  password,
}: IAuthentication): Promise<APIResponse<IAuthenticationResponse>> {
  return axios
    .post(`${API_BASE_URL}/login`, { email, password })
    .then((result) => {
      if (result.data && result.data.Success) {
        return right(result.data);
      } else {
        return left(normalizeError(result));
      }
    })
    .catch((err) => {
      return left(normalizeError(err));
    }) as any;
}

export const useAuthenticationStore = create<AuthenticationStore>()(
  persist(
    (set) => ({
      errorMsg: "",
      successMsg: "",
      authenticate: async (params: IAuthentication) => {
        const response = await authenticate(params);
        if (isRight(response)) {
          return set({
            user: response.right.Data?.User || undefined,
            token: response.right.Data?.Token || undefined,
            errorMsg: "",
            successMsg: "Vous êtes maintenant connecté.",
          });
        }

        return set({
          errorMsg: response.left.message,
          successMsg: "",
        });
      },
      clearAuthentication: () =>
        set({
          user: undefined,
          token: undefined,
          errorMsg: "",
          successMsg: "",
        }),
    }),
    {
      name: LOCAL_STORAGE_KEY,
      partialize: (state) => ({ token: state.token }),
    },
  ),
);
