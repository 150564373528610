import styled from "styled-components";

export const PaginationButton = styled.button`
  padding: 10px;
  text-align: center;
  min-width: initial;
  font-family: "GothamPro", sans-serif;
  color: #eeeeee;
  background-color: rgba(49, 50, 54, 0.6);

  &:hover,
  &.isActive {
    background-color: rgba(49, 50, 54, 1);
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

export const PrimaryButton = styled.button`
  background-color: #7b5bc6;
  color: #eeeeee;

  &:hover {
    background-color: #493676;
  }
`;

export const DangerButton = styled.button`
  background-color: #c0392b;
  color: #eeeeee;

  &:hover {
    background-color: #b43720;
  }
`;

export const SuccessButton = styled.button`
  background-color: #1e8449;
  color: #eeeeee;

  &:hover {
    background-color: #1a6a3c;
  }
`;

export const DarkButton = styled.button`
  background-color: #35393c;
  color: #eeeeee;

  &:hover {
    background-color: #292c2e;
  }
`;
