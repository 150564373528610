import { useCallback, useEffect, useState } from "react";
import { SubTitle, Title } from "../../../../components/Title/title.styles";
import { Column, Grid } from "../../../../components/Utils/flexbox.styles";
import { Icon } from "@iconify/react";
import { ErrorAlert } from "../../../../components/Alerts/alerts.styles";
import { Pane } from "../../../../components/Pane/pane.styles";
import { Loader } from "../../../../components/Utils/loader";
import { InfoLabel, InfoContent } from "../Account/account.styles";
import { useProductsStore } from "../../../../store/products";
import { useParams } from "react-router-dom";
import Markdown from "react-markdown";
import { formatDate } from "../../../../utils/dates";

export function ManageProductsDetails() {
  const [isLoading, setIsLoading] = useState(true);
  const { errorMsg, selectedProduct, loadProduct } = useProductsStore();
  const { id } = useParams();
  const fetchProduct = useCallback(() => {
    if (id) {
      setIsLoading(true);
      loadProduct(id).then(() => setIsLoading(false));
    }
  }, [loadProduct]);

  useEffect(() => {
    fetchProduct();
  }, [fetchProduct]);

  return (
    <Column>
      <Title>Détails de lingrédient</Title>
      {isLoading ? (
        <Pane>
          <Loader state={isLoading} />
        </Pane>
      ) : (
        <>
          {errorMsg.length > 0 ? (
            <ErrorAlert>
              <span>
                <Icon icon="ic:twotone-error" />
                {errorMsg}
              </span>
            </ErrorAlert>
          ) : (
            <>
              <Pane>
                <SubTitle>Informations</SubTitle>
                <Grid>
                  <Column
                    style={{
                      borderRight: "1px solid grey",
                      paddingRight: "20px",
                    }}
                  >
                    <div>
                      <InfoLabel>Nom de lingrédient</InfoLabel>
                      <InfoContent>{selectedProduct?.Name}</InfoContent>
                    </div>
                    <div>
                      <InfoLabel>Date de création</InfoLabel>
                      <InfoContent>
                        {formatDate(selectedProduct?.CreatedAt)}
                      </InfoContent>
                    </div>
                  </Column>
                  <Column style={{ paddingLeft: "20px" }}>
                    <div>
                      <InfoLabel>Date de dernière mise à jour</InfoLabel>
                      <InfoContent>
                        {formatDate(selectedProduct?.UpdatedAt)}
                      </InfoContent>
                    </div>
                  </Column>
                </Grid>
              </Pane>
              <Pane>
                <Title>Description</Title>
                <Markdown>{selectedProduct?.Text}</Markdown>
              </Pane>
            </>
          )}
        </>
      )}
    </Column>
  );
}
