import styled from "styled-components";

export const FlexBox = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  &.aligned {
    align-items: center;
    justify-content: space-between;
  }

  &.centered {
    align-items: center;
    justify-content: center;
  }

  &.horizontal-aligned {
    align-items: center;
    gap: 5px;
  }

  &.wrap {
    flex-wrap: wrap;
  }
`;

export const MultipleFormGroupItems = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  div {
    min-width: 200px;
    width: auto;
    flex-grow: 1;

    @media only screen and (max-width: 1200px) {
      width: 100%;
    }
  }
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  span {
    color: lightgrey;
  }

  input,
  select {
    padding: 8px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  &.full {
    width: 100%;
    height: 100%;
  }

  &.centered {
    align-items: center;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
`;
