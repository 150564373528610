import { Route, Routes } from "react-router-dom";
import ManageRecipes from "./index";
import ManageRecipeAdd from "./add/add";
import Error404 from "../../../errors/404";
import { ManageRecipesTypesDetails } from "./index/types/details";
import { ManageRecipesAddType } from "./index/types/add";
import { ManageRecipesDetails } from "./details/details";
import { ManageRecipesDetailsContent } from "./details/content";
import { ManageRecipesVersionsAdd } from "./recipe-versions/add";

export default function ManageRecipesRooter() {
  return (
    <Routes>
      <Route path="" element={<ManageRecipes />} />
      <Route path="/details/:id" element={<ManageRecipesDetails />} />
      <Route path="add" element={<ManageRecipeAdd />} />
      <Route
        path="/types/details/:id"
        element={<ManageRecipesTypesDetails />}
      />
      <Route path="/versions/:id/add" element={<ManageRecipesVersionsAdd />} />
      <Route
        path="/details/content/:id"
        element={<ManageRecipesDetailsContent />}
      />
      <Route path="/types/add/" element={<ManageRecipesAddType />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
}
