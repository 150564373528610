import { create } from "zustand";
import { API_BASE_URL } from ".";
import type { AccountStore, UserInfos } from "./types";
import { type APIResponse } from "../types/definitions";
import { normalizeError } from "../helpers/normalize-error";
import { isRight, left, right } from "fp-ts/lib/Either";
import apiClient from "../axios";

export function fetchAccountInfos(): Promise<APIResponse<UserInfos>> {
  return apiClient
    .get(`${API_BASE_URL}/account`)
    .then((result) => {
      if (result.data && result.data.Success) {
        return right(result.data);
      } else {
        return left(normalizeError(result));
      }
    })
    .catch((err) => {
      return left(normalizeError(err));
    }) as any;
}

export function updateAccountPassword(
  oldPassword: string,
  newPassword: string,
): Promise<APIResponse<void>> {
  return apiClient
    .post(`${API_BASE_URL}/account/panel/update-password`, {
      oldPassword,
      newPassword,
    })
    .then((result) => {
      if (result.data && result.data.Success) {
        return right(result.data);
      } else {
        return left(normalizeError(result));
      }
    })
    .catch((err) => {
      return left(normalizeError(err));
    }) as any;
}

export const useAccountStore = create<AccountStore>()((set) => ({
  errorMsg: "",
  successMsg: "",
  fetchAccount: async () => {
    const response = await fetchAccountInfos();

    if (isRight(response)) {
      return set((state) => ({
        ...state,
        user: response.right.Data,
        errorMsg: "",
        successMsg: "",
      }));
    }

    return set((state) => ({
      ...state,
      errorMsg: response.left.message,
      successMsg: "",
    }));
  },
  updatePassword: async (oldPassword: string, newPassword: string) => {
    const response = await updateAccountPassword(oldPassword, newPassword);
    if (isRight(response)) {
      return set((state) => ({
        ...state,
        errorMsg: "",
        successMsg: "Votre mot de passe a bien été modifié.",
      }));
    }

    return set((state) => ({
      ...state,
      errorMsg: response.left.message,
      successMsg: "",
    }));
  },
}));
