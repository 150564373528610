import { Route, Routes } from "react-router-dom";
import Error404 from "../../../errors/404";
import ManageProducts from "../ManageProducts";
import ManageProductsAdd from "./add";
import { ManageProductsDetails } from "./details";

export default function ManageProductsRooter() {
  return (
    <Routes>
      <Route path="" element={<ManageProducts />} />
      <Route path="add" element={<ManageProductsAdd />} />
      <Route path="details/:id" element={<ManageProductsDetails />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
}
