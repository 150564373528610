import { useState, useCallback } from "react";
import type { ZodIssue } from "zod";
import { z, ZodError } from "zod";
import {
  ErrorMessages,
  SuccessMessage,
} from "../../../../components/Alerts/alerts";
import { PrimaryButton } from "../../../../components/Buttons/buttons.styles";
import { Input } from "../../../../components/Input/input.styles";
import { Title } from "../../../../components/Title/title.styles";
import {
  Column,
  FlexBox,
  FormGroup,
} from "../../../../components/Utils/flexbox.styles";
import { Loader } from "../../../../components/Utils/loader";
import type { ValidationErrors } from "../../../../types/definitions";
import { useProductsStore } from "../../../../store/products";
import { MarkdownEditor } from "../../../../components/Markdown/markdown-editor";

export default function ManageProductsAdd() {
  const [productName, setProductName] = useState<string>("");
  const [productDescription, setProductDescription] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorsList, setErrorsList] = useState<ValidationErrors>({});

  const { errorMsg, createProduct, successMsg, setSuccessMsg } =
    useProductsStore();

  const onSubmit = useCallback(async () => {
    setErrorsList({});
    setSuccessMsg("");
    const validation = z.object({
      name: z.string().min(1, {
        message: "Le nom de l'ingrédient doit contenir au moins 1 caractère.",
      }),
    });

    try {
      const result = validation.parse({ name: productName });
      setIsLoading(true);
      await createProduct({ ...result, description: productDescription });
      setProductName("");
      setProductDescription("");
    } catch (error) {
      if (error instanceof ZodError) {
        setErrorsList(
          (error as ZodError).errors.reduce(
            (a, value: ZodIssue) => ({
              ...a,
              [value.path[0]]: value.message,
            }),
            {},
          ),
        );
      } else {
        setErrorsList({ default: "Une erreur inattendue est survenue." });
      }
    } finally {
      setIsLoading(false);
    }
  }, [setSuccessMsg, productName, createProduct, productDescription]);
  return (
    <Column>
      <Title>Ajouter un nouveau aliment</Title>

      {Object.keys(errorsList).length > 0 && (
        <ErrorMessages errorsList={errorsList} />
      )}
      {errorMsg && <ErrorMessages errorsList={errorMsg} />}
      {successMsg && <SuccessMessage successMsg={successMsg} />}

      <Column>
        <FormGroup>
          <span>Nom du traitement:</span>
          <Input
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
            isError={Boolean(errorsList?.name)}
            placeholder="Carotte"
          />
        </FormGroup>
        <FormGroup>
          <span>Description:</span>
          <MarkdownEditor
            onChange={setProductDescription}
            value={productDescription}
          />
        </FormGroup>
        <FlexBox className="centered">
          <PrimaryButton onClick={onSubmit} disabled={isLoading}>
            {isLoading ? <Loader state={isLoading} /> : "Valider"}
          </PrimaryButton>
        </FlexBox>
      </Column>
    </Column>
  );
}
