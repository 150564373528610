import styled from "styled-components";
import { media_device } from "../../../utils/media-device";

export const PanelWrapper = styled.div`
  display: grid;

  grid-template-columns: 70px 1fr;

  @media ${media_device.laptop} {
    grid-template-columns: 300px 1fr;
  }
  height: 100%;

  main {
    background-color: var(--onc-black);
    color: lightgray;
    padding: 0 20px;
  }
`;
