import { useParams } from "react-router-dom";
import { useRecipeTypesStore } from "../../../../../../store/recipes-types";
import { Loader } from "../../../../../../components/Utils/loader";
import { Icon } from "@iconify/react";
import { useState, useCallback, useEffect } from "react";
import { ErrorAlert } from "../../../../../../components/Alerts/alerts.styles";
import { Pane } from "../../../../../../components/Pane/pane.styles";
import {
  Title,
  SubTitle,
} from "../../../../../../components/Title/title.styles";
import {
  Column,
  Grid,
} from "../../../../../../components/Utils/flexbox.styles";
import { InfoLabel, InfoContent } from "../../../Account/account.styles";
import { formatDate } from "../../../../../../utils/dates";

export function ManageRecipesTypesDetails() {
  const [isLoading, setIsLoading] = useState(true);

  const { loadRecipeType, errorMsg, selectedRecipeType } =
    useRecipeTypesStore();
  const { id } = useParams();
  const fetchRecipeType = useCallback(() => {
    if (id) {
      setIsLoading(true);
      loadRecipeType(id).then(() => setIsLoading(false));
    }
  }, [loadRecipeType]);

  useEffect(() => {
    fetchRecipeType();
  }, [fetchRecipeType]);

  return (
    <Column>
      <Title>Détails du type de recette</Title>
      {isLoading ? (
        <Pane>
          <Loader state={isLoading} />
        </Pane>
      ) : (
        <>
          {errorMsg.length > 0 ? (
            <ErrorAlert>
              <span>
                <Icon icon="ic:twotone-error" />
                {errorMsg}
              </span>
            </ErrorAlert>
          ) : (
            <>
              <Pane>
                <SubTitle>Informations</SubTitle>
                <Grid>
                  <Column
                    style={{
                      borderRight: "1px solid grey",
                      paddingRight: "20px",
                    }}
                  >
                    <div>
                      <InfoLabel>Nom du traitement</InfoLabel>
                      <InfoContent>{selectedRecipeType?.Name}</InfoContent>
                    </div>
                    <div>
                      <InfoLabel>Date de création</InfoLabel>
                      <InfoContent>
                        {formatDate(selectedRecipeType?.CreatedAt)}
                      </InfoContent>
                    </div>
                  </Column>
                  <Column style={{ paddingLeft: "20px" }}>
                    <div>
                      <InfoLabel>Date de dernière mise à jour</InfoLabel>
                      <InfoContent>
                        {formatDate(selectedRecipeType?.UpdatedAt)}
                      </InfoContent>
                    </div>
                  </Column>
                </Grid>
              </Pane>
            </>
          )}
        </>
      )}
    </Column>
  );
}
