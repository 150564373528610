import { useCallback, useState } from "react";
import { Title } from "../../../../../components/Title/title.styles";
import {
  Column,
  FlexBox,
  FormGroup,
} from "../../../../../components/Utils/flexbox.styles";
import { PrimaryButton } from "../../../../../components/Buttons/buttons.styles";
import { MarkdownEditor } from "../../../../../components/Markdown/markdown-editor";
import { Loader } from "../../../../../components/Utils/loader";
import { ManageRecipesAddSubProduct } from "../add/product-add";
import { Input } from "../../../../../components/Input/input.styles";
import type { IProductStep } from "../add/add";
import { useRecipesVersionsStore } from "../../../../../store/recipes-versions";
import type { ZodIssue } from "zod";
import { z, ZodError } from "zod";
import type { ValidationErrors } from "../../../../../types/definitions";
import {
  ErrorMessages,
  SuccessMessage,
} from "../../../../../components/Alerts/alerts";
import { useParams } from "react-router-dom";

export function ManageRecipesVersionsAdd() {
  const [title, setTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [selectedProducts, setSelectedProducts] = useState<IProductStep[]>([]);

  const [content, setContent] = useState<string>("");

  const [errorsList, setErrorsList] = useState<ValidationErrors>({});

  const { id } = useParams();

  const { setSuccessMsg, createRecipeVersion, errorMsg, successMsg } =
    useRecipesVersionsStore();

  const onSubmit = useCallback(async () => {
    setErrorsList({});
    setSuccessMsg("");
    const validation = z.object({
      title: z.string().min(1, {
        message: "Le nom de la recette doit contenir au moins 1 caractère.",
      }),
      content: z.string().min(1, {
        message: "Le contenu de la recette doit contenir au moins 1 caractère.",
      }),
    });

    try {
      const result = validation.parse({
        title,
        content,
      });
      setIsLoading(true);
      await createRecipeVersion({
        ...result,
        selectedProducts,
        id: parseInt(id ?? ""),
      });
      setTitle("");
      setSelectedProducts([]);
    } catch (error) {
      if (error instanceof ZodError) {
        setErrorsList(
          (error as ZodError).errors.reduce(
            (a, value: ZodIssue) => ({
              ...a,
              [value.path[0]]: value.message,
            }),
            {},
          ),
        );
      } else {
        setErrorsList({ default: "Une erreur inattendue est survenue." });
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    content,
    createRecipeVersion,
    id,
    selectedProducts,
    setSuccessMsg,
    title,
  ]);
  return (
    <Column>
      <Title>Ajouter une nouvelle version</Title>
      <Column>
        {errorsList && <ErrorMessages errorsList={errorsList} />}
        {errorMsg && <ErrorMessages errorsList={errorMsg} />}
        {successMsg && <SuccessMessage successMsg={successMsg} />}
        <FormGroup>
          <span>Titre de la nouvelle version de la recette:</span>
          <Input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Gateau au chocolat"
          />
        </FormGroup>
        <ManageRecipesAddSubProduct
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
        />
        <Column>
          <span>Contenue de la recette</span>
          <MarkdownEditor onChange={setContent} value={content} />
        </Column>
        <FlexBox className="centered">
          <PrimaryButton onClick={onSubmit} disabled={isLoading}>
            {isLoading ? <Loader state={isLoading} /> : "Valider"}
          </PrimaryButton>
        </FlexBox>
      </Column>
    </Column>
  );
}
