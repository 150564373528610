import AnalyticPane from "../../../components/Pane/analytic-pane";
import { Title } from "../../../components/Title/title.styles";
import { Column, FlexBox } from "../../../components/Utils/flexbox.styles";

export function Analytics() {
  return (
    <Column>
      <Title>Analyses</Title>
      <FlexBox className="aligned wrap" style={{ gap: "10px" }}>
        <AnalyticPane title="Nombre de membres" figure="200" evolution={2.0} />
        <AnalyticPane title="Nombre de recettes" figure="10" evolution={-4.0} />
        <AnalyticPane
          title="Revenues mensuels"
          figure="200$"
          evolution={10.0}
        />
      </FlexBox>
    </Column>
  );
}
