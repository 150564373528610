import { styled } from "styled-components";
import { media_device } from "../../utils/media-device";

export const LoginWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #19181d;
  color: lightgray;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LoginForm = styled.div`
  margin: 10px;
  background-color: #2c2d2f;
  border-radius: 10px;
  padding: 30px;
  min-width: 80vw;
  @media ${media_device.laptop} {
    padding: 50px;
    min-height: 40vh;
    min-width: 20vw;
  }
`;
