import { Icon } from "@iconify/react";
import { PrimaryButton } from "../../../../../../components/Buttons/buttons.styles";
import {
  Input,
  InputIconWrapper,
} from "../../../../../../components/Input/input.styles";
import { SubTitle } from "../../../../../../components/Title/title.styles";
import {
  Column,
  FlexBox,
} from "../../../../../../components/Utils/flexbox.styles";
import { useNavigate } from "react-router-dom";
import { Pagination } from "../../../../../../components/Pagination/pagination";
import { Table } from "../../../../../../components/Table/table.styles";
import { useCallback, useEffect, useState } from "react";
import { Loader } from "../../../../../../components/Utils/loader";
import { useRecipeTypesStore } from "../../../../../../store/recipes-types";
import _debounce from "lodash/debounce";
import { DEFAULT_PAGE_SIZE } from "../../../../../../utils/constants";
import { ErrorMessages } from "../../../../../../components/Alerts/alerts";

export function ManageRecipesTypesList() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { loadRecipeTypes, errorMsg, types, totalPages } =
    useRecipeTypesStore();

  const [currentPage, setCurrentPage] = useState<number>(1);

  const [searchInput, setSearchInput] = useState("");

  const fetchRecipeTypes = useCallback(
    (nameInput: string) => {
      setIsLoading(true);
      loadRecipeTypes({
        name: nameInput,
        pageNumber: currentPage,
        pageSize: DEFAULT_PAGE_SIZE,
      }).then(() => setIsLoading(false));
    },
    [currentPage, loadRecipeTypes],
  );

  useEffect(() => {
    fetchRecipeTypes(searchInput);
  }, [fetchRecipeTypes, searchInput]);

  const debounceFn = useCallback(
    _debounce((nameInput: string) => fetchRecipeTypes(nameInput), 300),
    [fetchRecipeTypes],
  );

  const handleSearchInput = (newInput: string) => {
    setSearchInput(newInput);
    debounceFn(newInput);
  };
  return (
    <Column>
      <SubTitle>Types de recette</SubTitle>
      {errorMsg && <ErrorMessages errorsList={errorMsg} />}
      <FlexBox className="aligned">
        <InputIconWrapper>
          <div className="icon">
            <Icon icon="ph:magnifying-glass-duotone" />
          </div>
          <Input
            value={searchInput}
            onChange={(e) => handleSearchInput(e.target.value)}
            placeholder="Rechercher par nom"
          />
        </InputIconWrapper>
        <PrimaryButton onClick={() => navigate("/manage-recipes/types/add")}>
          Ajouter un type
        </PrimaryButton>
      </FlexBox>
      <Table>
        <thead>
          <tr>
            <th>#</th>
            <th>Nom</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={3}>
                <Loader state={isLoading} />
              </td>
            </tr>
          ) : (
            <>
              {types && types.length > 0 ? (
                types.map((type, index) => (
                  <tr key={type.ID}>
                    <td>{index + 1}</td>
                    <td>{type.Name}</td>
                    <td>
                      <FlexBox className="horizontal-aligned">
                        <PrimaryButton
                          onClick={() =>
                            navigate(`/manage-recipes/types/details/${type.ID}`)
                          }
                        >
                          Voir
                        </PrimaryButton>
                      </FlexBox>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td style={{ textAlign: "center" }} colSpan={3}>
                    Aucun résultat
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </Table>
      <Pagination
        totalPages={totalPages}
        page={currentPage}
        onChange={setCurrentPage}
      />
    </Column>
  );
}
