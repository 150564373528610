import { Route, Routes, useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar/sidebar";
import { PanelWrapper } from "./styles/panel.styles";
import { Analytics } from "./sections/analytics";
import Error404 from "../errors/404";
import ManageRecipesRooter from "./sections/ManageRecipes/rooter";
import ManageUsersRooter from "./sections/ManageUsers/rooter";
import ManageProductsRooter from "./sections/ManageProducts/rooter";
import ManageAllergiesRooter from "./sections/ManageAllergies/rooter";
import Account from "./sections/Account";
import ManageTreatmentsRooter from "./sections/ManageTreatments/rooter";
import { useAuthenticationStore } from "../../store/authentication";
import { useEffect } from "react";

export default function Panel() {
  const { token } = useAuthenticationStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token || token === "") {
      navigate("/login");
    }
  }, [navigate, token]);

  return (
    <PanelWrapper>
      <Sidebar />
      <main>
        <Routes>
          <Route path="" element={<Analytics />} />
          <Route path="account" element={<Account />} />
          <Route path="manage-users/*" element={<ManageUsersRooter />} />
          <Route path="manage-products/*" element={<ManageProductsRooter />} />
          <Route path="manage-recipes/*" element={<ManageRecipesRooter />} />
          <Route
            path="manage-treatments/*"
            element={<ManageTreatmentsRooter />}
          />
          <Route
            path="manage-allergies/*"
            element={<ManageAllergiesRooter />}
          />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </main>
    </PanelWrapper>
  );
}
