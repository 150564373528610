import { Icon } from "@iconify/react";
import { ErrorAlert } from "../../../../components/Alerts/alerts.styles";
import { Pane } from "../../../../components/Pane/pane.styles";
import { Table } from "../../../../components/Table/table.styles";
import { SubTitle, Title } from "../../../../components/Title/title.styles";
import {
  Column,
  FlexBox,
  Grid,
} from "../../../../components/Utils/flexbox.styles";
import { Loader } from "../../../../components/Utils/loader";
import { useAllergiesStore } from "../../../../store/allergies";
import { InfoLabel, InfoContent } from "../Account/account.styles";
import { useCallback, useEffect, useState } from "react";
import { PrimaryButton } from "../../../../components/Buttons/buttons.styles";
import { useNavigate, useParams } from "react-router-dom";
import { formatDate } from "../../../../utils/dates";

export function ManageAllergiesDetails() {
  const [isLoading, setIsLoading] = useState(true);

  const { id } = useParams();

  const navigate = useNavigate();

  const { loadAllergy, errorMsg, selectedAllergy } = useAllergiesStore();

  const fetchAllergy = useCallback(() => {
    if (id) {
      setIsLoading(true);
      loadAllergy(id).then(() => setIsLoading(false));
    }
  }, [loadAllergy]);

  useEffect(() => {
    fetchAllergy();
  }, [fetchAllergy]);

  return (
    <Column>
      <Title>Détails de lallergie</Title>
      {isLoading ? (
        <Pane>
          <Loader state={isLoading} />
        </Pane>
      ) : (
        <>
          {errorMsg.length > 0 ? (
            <ErrorAlert>
              <span>
                <Icon icon="ic:twotone-error" />
                {errorMsg}
              </span>
            </ErrorAlert>
          ) : (
            <>
              <Pane>
                <SubTitle>Informations</SubTitle>
                <Grid>
                  <Column
                    style={{
                      borderRight: "1px solid grey",
                      paddingRight: "20px",
                    }}
                  >
                    <div>
                      <InfoLabel>Nom de lallergie</InfoLabel>
                      <InfoContent>{selectedAllergy?.Name}</InfoContent>
                    </div>
                    <div>
                      <InfoLabel>Date de création</InfoLabel>
                      <InfoContent>
                        {formatDate(selectedAllergy?.CreatedAt)}
                      </InfoContent>
                    </div>
                  </Column>
                  <Column style={{ paddingLeft: "20px" }}>
                    <div>
                      <InfoLabel>Date de dernière mise à jour</InfoLabel>
                      <InfoContent>
                        {formatDate(selectedAllergy?.UpdatedAt)}
                      </InfoContent>
                    </div>
                  </Column>
                </Grid>
              </Pane>
              <Pane>
                <SubTitle>Liste des ingrédients concernés</SubTitle>
                <Table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nom</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedAllergy?.Ingredients &&
                    selectedAllergy.Ingredients.length > 0 ? (
                      <>
                        {selectedAllergy.Ingredients.map(
                          (ingredient, index) => (
                            <tr key={ingredient.ID}>
                              <td>{index + 1}</td>
                              <td>{ingredient.Name}</td>
                              <td>
                                <FlexBox className="horizontal-aligned">
                                  <PrimaryButton
                                    onClick={() =>
                                      navigate(
                                        `/manage-products/details/${ingredient.ID}`,
                                      )
                                    }
                                  >
                                    Voir
                                  </PrimaryButton>
                                </FlexBox>
                              </td>
                            </tr>
                          ),
                        )}
                      </>
                    ) : (
                      <tr>
                        <td style={{ textAlign: "center" }} colSpan={3}>
                          Aucun résultat
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Pane>
            </>
          )}
        </>
      )}
    </Column>
  );
}
