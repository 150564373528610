import { Route, Routes } from "react-router-dom";
import Error404 from "../../../errors/404";
import ManageAllergiesIndex from ".";
import ManageAllergiesAdd from "./add/add";
import { ManageAllergiesDetails } from "./details";

export default function ManageAllergiesRooter() {
  return (
    <Routes>
      <Route path="" element={<ManageAllergiesIndex />} />
      <Route path="add" element={<ManageAllergiesAdd />} />
      <Route path="details/:id" element={<ManageAllergiesDetails />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
}
