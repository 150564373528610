import { Icon } from "@iconify/react";
import {
  ErrorAlert,
  InfoAlert,
} from "../../../../components/Alerts/alerts.styles";
import { Title } from "../../../../components/Title/title.styles";
import { Column, FlexBox } from "../../../../components/Utils/flexbox.styles";
import { Table } from "../../../../components/Table/table.styles";
import {
  Input,
  InputIconWrapper,
} from "../../../../components/Input/input.styles";
import { useCallback, useEffect, useState } from "react";
import { Loader } from "../../../../components/Utils/loader";
import { useUsersStore } from "../../../../store/users";
import { Pagination } from "../../../../components/Pagination/pagination";
import {
  DangerButton,
  PrimaryButton,
  SuccessButton,
} from "../../../../components/Buttons/buttons.styles";
import { DEFAULT_PAGE_SIZE } from "../../../../utils/constants";
import _debounce from "lodash/debounce";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../../utils/dates";

export default function ManageUsersIndex() {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { loadUsers, users, errorMsg } = useUsersStore();

  const [currentPage, setCurrentPage] = useState<number>(1);

  const [searchInput, setSearchInput] = useState("");

  const navigate = useNavigate();

  const fetchUsers = useCallback(
    (emailInput: string) => {
      setIsLoading(true);
      loadUsers({
        pageNumber: currentPage,
        pageSize: DEFAULT_PAGE_SIZE,
        email: emailInput,
      }).then(() => setIsLoading(false));
    },
    [currentPage, loadUsers],
  );

  useEffect(() => {
    fetchUsers(searchInput);
  }, [fetchUsers]);

  const debounceFn = useCallback(
    _debounce((emailInput: string) => fetchUsers(emailInput), 300),
    [fetchUsers],
  );

  const handleSearchInput = (newInput: string) => {
    setSearchInput(newInput);
    debounceFn(newInput);
  };

  return (
    <Column>
      <Title>Gestion des utilisateurs</Title>
      <InfoAlert>
        <span>
          <Icon icon="ic:twotone-info" />
          Vous pouvez au travers de ce panel gérer l&apos;ensemble des
          utilisateurs inscris sur OncAdvice.
        </span>
      </InfoAlert>
      <InputIconWrapper>
        <div className="icon">
          <Icon icon="ph:magnifying-glass-duotone" />
        </div>
        <Input
          value={searchInput}
          onChange={(e) => handleSearchInput(e.target.value)}
          placeholder="Rechercher par mail"
        />
      </InputIconWrapper>
      {errorMsg.length > 0 && (
        <ErrorAlert>
          <span>
            <Icon icon="ic:twotone-error" />
            {errorMsg}
          </span>
        </ErrorAlert>
      )}

      <Table>
        <thead>
          <tr>
            <th>#</th>
            <th>Prénom</th>
            <th>Nom</th>
            <th>Email</th>
            <th>Date de naissance</th>
            <th>Désactivé</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={7}>
                <Loader state={isLoading} />
              </td>
            </tr>
          ) : (
            <>
              {users && users.length > 0 ? (
                users.map((user, index) => (
                  <tr key={user.ID}>
                    <td>{index + 1}</td>
                    <td>{user.Firstname}</td>
                    <td>{user.Lastname}</td>
                    <td>{user.Email}</td>
                    <td>{formatDate(user.BirthDate)}</td>
                    <td>{user.IsFlagged ? "Oui" : "Non"}</td>
                    <td>
                      <FlexBox className="horizontal-aligned">
                        <PrimaryButton
                          onClick={() =>
                            navigate(`/manage-users/details/${user.ID}`)
                          }
                        >
                          Voir
                        </PrimaryButton>
                        {!user.IsFlagged ? (
                          <DangerButton>Désactiver</DangerButton>
                        ) : (
                          <SuccessButton>Activer</SuccessButton>
                        )}
                      </FlexBox>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td style={{ textAlign: "center" }} colSpan={7}>
                    Aucun résultat
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </Table>
      <Pagination
        totalPages={users ? users.length : 0}
        page={currentPage}
        onChange={setCurrentPage}
      />
    </Column>
  );
}
