import { create } from "zustand";
import { API_BASE_URL } from ".";
import type { Allergy, AllergyStore } from "./types";
import axios from "axios";
import type {
  APIResponse,
  PaginatedAPIResponse,
  Pagination,
} from "../types/definitions";
import { normalizeError } from "../helpers/normalize-error";
import { isRight, left, right } from "fp-ts/lib/Either";
import { DEFAULT_PAGE_SIZE } from "../utils/constants";

export interface IFetchAllergiesParams extends Pagination {
  name?: string;
}

export interface ICreateAllergyParams {
  name: string;
  productsList: string[];
}

export function fetchAllergies({
  pageNumber,
  pageSize,
  name,
}: IFetchAllergiesParams): PaginatedAPIResponse<Array<Allergy>> {
  const params: Record<string, any> | URLSearchParams | undefined = {
    PageSize: pageSize,
    PageNumber: pageNumber,
  };

  if (name !== undefined && name !== "") {
    params.Name = name;
  }
  return axios
    .get(`${API_BASE_URL}/allergies`, { params })
    .then((result) => right(result.data))
    .catch((err) => left(normalizeError(err)));
}

export function fetchAllergy(id: string): APIResponse<Allergy> {
  return axios
    .get(`${API_BASE_URL}/allergies/${id}`)
    .then((result) => right(result.data))
    .catch((err) => left(normalizeError(err)));
}

export function createAllergy({
  name,
  productsList,
}: ICreateAllergyParams): Promise<APIResponse<undefined>> {
  return axios
    .post(`${API_BASE_URL}/allergies`, {
      Name: name,
      ProductLists: productsList,
    })
    .then((result) => {
      if (result.data && result.data.Success) {
        return right(result);
      } else {
        return left(normalizeError(result));
      }
    })
    .catch((err) => {
      return left(normalizeError(err));
    }) as any;
}

export const useAllergiesStore = create<AllergyStore>()((set) => ({
  allergies: [],
  errorMsg: "",
  successMsg: "",
  totalRecords: 0,
  totalPages: 0,
  pageSize: DEFAULT_PAGE_SIZE,
  loadAllergies: async (params: IFetchAllergiesParams) => {
    const response = await fetchAllergies(params);
    if (isRight(response)) {
      return set((state) => ({
        ...state,
        pageSize: response.right.Data.pageSize,
        totalRecords: response.right.Data.totalRecords,
        totalPages: response.right.Data.totalPages,
        allergies: response.right.Data.data ?? [],
        errorMsg: "",
      }));
    }

    return set((state) => ({ ...state, errorMsg: response.left.message }));
  },
  loadAllergy: async (id: string) => {
    const response = await fetchAllergy(id);
    if (isRight(response)) {
      return set((state) => ({
        ...state,
        selectedAllergy: response.right.Data,
        errorMsg: "",
      }));
    }

    return set((state) => ({ ...state, errorMsg: response.left.message }));
  },
  createAllergy: async (params: ICreateAllergyParams) => {
    const response = await createAllergy(params);

    if (isRight(response)) {
      return set((state) => ({
        ...state,
        errorMsg: "",
        successMsg: "Allergie ajoutée avec succès.",
      }));
    }

    return set((state) => ({
      ...state,
      errorMsg: response.left.message,
      successMsg: "",
    }));
  },
  setSuccessMsg: (content: string) => {
    return set((state) => ({
      ...state,
      errorMsg: "",
      successMsg: content,
    }));
  },
}));
