import { useState, useCallback } from "react";
import type { ZodIssue } from "zod";
import { z, ZodError } from "zod";
import {
  ErrorMessages,
  SuccessMessage,
} from "../../../../components/Alerts/alerts";
import { PrimaryButton } from "../../../../components/Buttons/buttons.styles";
import { Input } from "../../../../components/Input/input.styles";
import { Title } from "../../../../components/Title/title.styles";
import {
  Column,
  FormGroup,
  FlexBox,
} from "../../../../components/Utils/flexbox.styles";
import { Loader } from "../../../../components/Utils/loader";
import { useTreatmentsStore } from "../../../../store/treatments";
import type { ValidationErrors } from "../../../../types/definitions";

export function ManageTreatmentsAdd() {
  const [treatmentName, setTreatmentName] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorsList, setErrorsList] = useState<ValidationErrors>({});

  const { errorMsg, createTreatment, successMsg, setSuccessMsg } =
    useTreatmentsStore();

  const onSubmit = useCallback(async () => {
    setErrorsList({});
    setSuccessMsg("");
    const validation = z.object({
      name: z.string().min(1, {
        message: "Le nom du traitement doit contenir au moins 1 caractère.",
      }),
    });

    try {
      const result = validation.parse({ name: treatmentName });
      setIsLoading(true);
      await createTreatment(result);
      setTreatmentName("");
    } catch (error) {
      if (error instanceof ZodError) {
        setErrorsList(
          (error as ZodError).errors.reduce(
            (a, value: ZodIssue) => ({
              ...a,
              [value.path[0]]: value.message,
            }),
            {},
          ),
        );
      } else {
        setErrorsList({ default: "Une erreur inattendue est survenue." });
      }
    } finally {
      setIsLoading(false);
    }
  }, [setSuccessMsg, treatmentName, createTreatment]);

  return (
    <Column>
      <Title>Ajouter un nouveau traitement</Title>

      {Object.keys(errorsList).length > 0 && (
        <ErrorMessages errorsList={errorsList} />
      )}
      {errorMsg && <ErrorMessages errorsList={errorMsg} />}
      {successMsg && <SuccessMessage successMsg={successMsg} />}

      <Column>
        <FormGroup>
          <span>Nom du traitement:</span>
          <Input
            value={treatmentName}
            onChange={(e) => setTreatmentName(e.target.value)}
            isError={Boolean(errorsList?.name)}
            placeholder="Anxiolitique"
          />
        </FormGroup>
        <FlexBox className="centered">
          <PrimaryButton onClick={onSubmit} disabled={isLoading}>
            {isLoading ? <Loader state={isLoading} /> : "Valider"}
          </PrimaryButton>
        </FlexBox>
      </Column>
    </Column>
  );
}
