// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --onc-black: #181c1f;
    --onc-orange: #F17B62;
    --onc-grey: #43474a;
    --onc-white: background: rgba(253, 253, 253, 1);
    --onc-green: rgba(56, 107, 105, 1);
}
`, "",{"version":3,"sources":["webpack://./src/variables.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,qBAAqB;IACrB,mBAAmB;IACnB,+CAA+C;IAC/C,kCAAkC;AACtC","sourcesContent":[":root {\n    --onc-black: #181c1f;\n    --onc-orange: #F17B62;\n    --onc-grey: #43474a;\n    --onc-white: background: rgba(253, 253, 253, 1);\n    --onc-green: rgba(56, 107, 105, 1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
