import { Pane } from "../../../../components/Pane/pane.styles";
import { SubTitle, Title } from "../../../../components/Title/title.styles";
import { Column, Grid } from "../../../../components/Utils/flexbox.styles";
import { InfoLabel, InfoContent } from "../Account/account.styles";

export function ManageUsersDetails() {
  return (
    <Column>
      <Title>Détails du compte</Title>
      <Pane>
        <SubTitle>Informations générales</SubTitle>
        <Grid>
          <Column
            style={{ borderRight: "1px solid grey", paddingRight: "20px" }}
          >
            <div>
              <InfoLabel>Nom</InfoLabel>
              <InfoContent>caca</InfoContent>
            </div>
            <div>
              <InfoLabel>Prénom</InfoLabel>
              <InfoContent>caca</InfoContent>
            </div>
            <div>
              <InfoLabel>Email</InfoLabel>
              <InfoContent>caca</InfoContent>
            </div>
          </Column>
          <Column style={{ paddingLeft: "20px" }}>
            <div>
              <InfoLabel>Addresse</InfoLabel>
              <InfoContent>caca</InfoContent>
            </div>
            <div>
              <InfoLabel>Date de naissance</InfoLabel>
              <InfoContent>caca</InfoContent>
            </div>
          </Column>
        </Grid>
      </Pane>
    </Column>
  );
}
