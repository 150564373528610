import type { ZodIssue } from "zod";
import { z, ZodError } from "zod";
import { useRecipeTypesStore } from "../../../../../../store/recipes-types";
import { useState, useCallback } from "react";
import {
  ErrorMessages,
  SuccessMessage,
} from "../../../../../../components/Alerts/alerts";
import { PrimaryButton } from "../../../../../../components/Buttons/buttons.styles";
import { Title } from "../../../../../../components/Title/title.styles";
import {
  Column,
  FormGroup,
  FlexBox,
} from "../../../../../../components/Utils/flexbox.styles";
import { Loader } from "../../../../../../components/Utils/loader";
import type { ValidationErrors } from "../../../../../../types/definitions";
import { Input } from "../../../../../../components/Input/input.styles";

export function ManageRecipesAddType() {
  const [recipeTypeName, setRecipeTypeName] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorsList, setErrorsList] = useState<ValidationErrors>({});

  const { errorMsg, createRecipeType, successMsg } = useRecipeTypesStore();

  const onSubmit = useCallback(async () => {
    setErrorsList({});
    const validation = z.object({
      name: z.string().min(1, {
        message:
          "Le nouveau type de recette doit contenir au moins 1 caractère.",
      }),
    });

    try {
      const result = validation.parse({ name: recipeTypeName });
      setIsLoading(true);
      await createRecipeType(result);
      setRecipeTypeName("");
    } catch (error) {
      if (error instanceof ZodError) {
        setErrorsList(
          (error as ZodError).errors.reduce(
            (a, value: ZodIssue) => ({
              ...a,
              [value.path[0]]: value.message,
            }),
            {},
          ),
        );
      } else {
        setErrorsList({ default: "Une erreur inattendue est survenue." });
      }
    } finally {
      setIsLoading(false);
    }
  }, [recipeTypeName, createRecipeType]);

  return (
    <Column>
      <Title>Ajouter un nouveau type de recette</Title>

      {Object.keys(errorsList).length > 0 && (
        <ErrorMessages errorsList={errorsList} />
      )}
      {errorMsg && <ErrorMessages errorsList={errorMsg} />}
      {successMsg && <SuccessMessage successMsg={successMsg} />}

      <Column>
        <FormGroup>
          <span>Nom du nouveau type:</span>
          <Input
            value={recipeTypeName}
            onChange={(e) => setRecipeTypeName(e.target.value)}
            isError={Boolean(errorsList?.name)}
            placeholder="Plateau de fromage"
          />
        </FormGroup>
        <FlexBox className="centered">
          <PrimaryButton onClick={onSubmit} disabled={isLoading}>
            {isLoading ? <Loader state={isLoading} /> : "Valider"}
          </PrimaryButton>
        </FlexBox>
      </Column>
    </Column>
  );
}
