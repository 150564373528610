import { Icon } from "@iconify/react";
import { PaginationButton } from "../Buttons/buttons.styles";
import { FlexBox } from "../Utils/flexbox.styles";

interface IPaginationprops {
  totalPages: number;
  page: number;
  onChange: (pageNumber: number) => void;
}

export function Pagination({ totalPages, page, onChange }: IPaginationprops) {
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
  return (
    <FlexBox
      className="horizontal-aligned"
      style={{ justifyContent: "flex-end" }}
    >
      {totalPages > 0 && (
        <PaginationButton
          key={"arrow-left"}
          onClick={() => onChange(page - 1)}
          disabled={page === 1}
        >
          <Icon icon={"material-symbols:chevron-left"} />
        </PaginationButton>
      )}

      {pageNumbers.map((number: number) => {
        return (
          <PaginationButton
            key={number}
            onClick={() => onChange(number)}
            className={number === page ? "isActive" : ""}
          >
            {number}
          </PaginationButton>
        );
      })}
      {totalPages > 0 && (
        <PaginationButton
          key={"arrow-right"}
          onClick={() => onChange(page + 1)}
          disabled={page === totalPages}
        >
          <Icon icon={"material-symbols:chevron-right"} />
        </PaginationButton>
      )}
    </FlexBox>
  );
}
