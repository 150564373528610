import { useLocation } from "react-router-dom";
import { PrimaryButton } from "../Buttons/buttons.styles";
import Icons from "../Icons/icons";
import { SideNav, SidebarLink, SidebarWrapper } from "./sidebar.styles";
import { Icon } from "@iconify/react";
import { useAuthenticationStore } from "../../store/authentication";

export default function Sidebar() {
  const currentPath = useLocation().pathname;

  const { clearAuthentication } = useAuthenticationStore();

  return (
    <SidebarWrapper>
      <div className="head">
        <Icons name="logo" />
      </div>
      <div className="item" style={{ flexGrow: 1 }}>
        <SideNav>
          <p>Général</p>
          <li>
            <SidebarLink
              href="/"
              className={currentPath.match("^/$") ? "active" : ""}
            >
              <Icon fontSize={"22px"} icon="lets-icons:home-duotone" />
              <span>Accueil</span>
            </SidebarLink>
          </li>
          <li>
            <SidebarLink
              href="/account"
              className={currentPath.match("^/account$") ? "active" : ""}
            >
              <Icon fontSize={"22px"} icon="ic:twotone-account-circle" />
              <span>Mon compte</span>
            </SidebarLink>
          </li>
          <p>Utilisateurs</p>
          <li>
            <SidebarLink
              href="/manage-users"
              className={currentPath.match("^/manage-users/*") ? "active" : ""}
            >
              <Icon fontSize={"22px"} icon="ph:users-three-duotone" />
              <span>Gestion des utilisateurs</span>
            </SidebarLink>
          </li>
          <p>Nutrition</p>
          <li>
            <SidebarLink
              href="/manage-recipes"
              className={
                currentPath.match("^/manage-recipes/*") ? "active" : ""
              }
            >
              <Icon fontSize={"22px"} icon="icon-park-twotone:book" />
              <span>Gestion des recettes</span>
            </SidebarLink>
            <SidebarLink
              href="/manage-products"
              className={
                currentPath.match("^/manage-products/*") ? "active" : ""
              }
            >
              <Icon fontSize={"22px"} icon="ph:carrot-duotone" />
              <span>Gestion des aliments</span>
            </SidebarLink>
          </li>
          <p>Santé</p>
          <li>
            <SidebarLink
              href="/manage-allergies"
              className={
                currentPath.match("^/manage-allergies/*") ? "active" : ""
              }
            >
              <Icon fontSize={"22px"} icon="ph:virus-duotone" />
              <span>Gestion des allergies</span>
            </SidebarLink>
          </li>
          <li>
            <SidebarLink
              href="/manage-treatments"
              className={
                currentPath.match("^/manage-treatments/*") ? "active" : ""
              }
            >
              <Icon fontSize={"22px"} icon="mdi:drugs" />
              <span>Gestion des traitements</span>
            </SidebarLink>
          </li>
          <p>Modération</p>
          <li>
            <SidebarLink
              href="/manage-comments"
              className={
                currentPath.match("^/manage-comments/*") ? "active" : ""
              }
            >
              <Icon fontSize={"22px"} icon="ic:twotone-message" />
              <span>Gestion des commentaires</span>
            </SidebarLink>
          </li>
        </SideNav>
      </div>
      <div className="item footer">
        <PrimaryButton className="full">
          <Icon fontSize={16} icon="material-symbols:logout" />
          <span onClick={clearAuthentication}>Se déconnecter</span>
        </PrimaryButton>
      </div>
    </SidebarWrapper>
  );
}
