import { Icon } from "@iconify/react";

export interface LoaderProps {
  state: boolean;
  noblur?: boolean;
}

export const Loader = ({ state, noblur }: LoaderProps) => {
  return state ? (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "grid",
        placeItems: "center",
        backdropFilter: noblur ? "" : "blur(2px)",
        zIndex: 999,
      }}
    >
      <Icon icon="eos-icons:loading" color="white" fontSize={32} />
    </div>
  ) : null;
};
