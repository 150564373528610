import AsyncSelect from "react-select/async";
import { DarkButton } from "../../../../../components/Buttons/buttons.styles";
import { DarkAsynSelectStyle } from "../../../../../components/Input/input.styles";
import axios from "axios";
import { debounce } from "lodash";
import { useRef, useEffect, useState } from "react";
import { API_BASE_URL } from "../../../../../store";
import { DEFAULT_PAGE_SIZE } from "../../../../../utils/constants";
import type { ActionMeta, SingleValue } from "react-select";
import type { OptionType } from "../../../../../types/definitions";
interface IManageAllergiesAddSubProductsProps {
  onChange: (input: OptionType) => void;
}

export function ManageAllergiesAddSubProduct(
  props: IManageAllergiesAddSubProductsProps,
) {
  const [isError, setIsError] = useState<boolean>(false);

  const cancelTokenSource = useRef<ReturnType<
    typeof axios.CancelToken.source
  > | null>(null);

  const loadOptions = async (inputValue: string) => {
    // If there is a previous request, cancel it
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel("Cancelling previous request");
    }

    // Create a new cancel token for the new request
    cancelTokenSource.current = axios.CancelToken.source();

    try {
      const response = await axios.get(`${API_BASE_URL}/ingredients`, {
        params: {
          PageSize: DEFAULT_PAGE_SIZE,
          PageNumber: 1,
          Name: inputValue,
        },
        cancelToken: cancelTokenSource.current.token, // Pass the cancel token
      });

      if (response.data) {
        return response.data.Data.data.map((product: any) => ({
          label: product.Name,
          value: product.ID,
        }));
      }
      return [];
    } catch (error) {
      return []; // Return empty options if there is an error or cancellation
    }
  };

  const debouncedLoadOptions = useRef(
    debounce(
      (inputValue: string, callback: (options: OptionType[]) => void) => {
        loadOptions(inputValue).then(callback);
      },
      500,
    ),
  ).current;

  // Cleanup on unmount to cancel any pending request
  useEffect(() => {
    return () => {
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel("Component unmounted");
      }
    };
  }, []);

  const [inputValue, setInputValue] = useState("");

  const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);

  const onSetValue = (
    newValue: SingleValue<OptionType>,
    actionMeta: ActionMeta<OptionType>,
  ) => {
    setIsError(false);
    setSelectedOption(newValue);
  };

  const onSelectValidate = () => {
    if (selectedOption == null) {
      setIsError(true);
    } else {
      props.onChange(selectedOption);
    }
  };

  return (
    <tr>
      <td>
        <AsyncSelect
          cacheOptions
          styles={DarkAsynSelectStyle(isError)}
          loadOptions={debouncedLoadOptions}
          onInputChange={setInputValue}
          onChange={onSetValue}
          placeholder="Rechercher un ingrédient"
          defaultOptions
          noOptionsMessage={() => `Aucun résultat pour "${inputValue}"`}
          loadingMessage={() => "Chargement en cours..."}
        />
      </td>
      <td>
        <DarkButton onClick={onSelectValidate}>Valider</DarkButton>
      </td>
    </tr>
  );
}
