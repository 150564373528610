import { useCallback, useEffect, useState } from "react";
import { SubTitle, Title } from "../../../../../components/Title/title.styles";
import { Column, Grid } from "../../../../../components/Utils/flexbox.styles";
import { useRecipesStore } from "../../../../../store/recipes";
import { Icon } from "@iconify/react";
import { ErrorAlert } from "../../../../../components/Alerts/alerts.styles";
import { Pane } from "../../../../../components/Pane/pane.styles";
import { Loader } from "../../../../../components/Utils/loader";
import { InfoContent, InfoLabel } from "../../Account/account.styles";
import { useParams } from "react-router-dom";
import { formatDate } from "../../../../../utils/dates";
import { ManageRecipesListVersions } from "../recipe-versions/list";

export function ManageRecipesDetails() {
  const [isLoading, setIsLoading] = useState(true);

  const { loadRecipe, errorMsg, selectedRecipe } = useRecipesStore();
  const { id } = useParams();
  const fetchRecipe = useCallback(() => {
    if (id) {
      setIsLoading(true);
      loadRecipe(id).then(() => setIsLoading(false));
    }
  }, [id, loadRecipe]);

  useEffect(() => {
    fetchRecipe();
  }, [fetchRecipe]);

  return (
    <Column>
      <Title>Informations générales</Title>
      {isLoading ? (
        <Pane>
          <Loader state={isLoading} />
        </Pane>
      ) : (
        <>
          {errorMsg.length > 0 ? (
            <ErrorAlert>
              <span>
                <Icon icon="ic:twotone-error" />
                {errorMsg}
              </span>
            </ErrorAlert>
          ) : (
            <>
              <Pane>
                <SubTitle>Informations</SubTitle>
                <Grid>
                  <Column
                    style={{
                      borderRight: "1px solid grey",
                      paddingRight: "20px",
                    }}
                  >
                    <div>
                      <InfoLabel>Nom de la recette</InfoLabel>
                      <InfoContent>{selectedRecipe?.Name}</InfoContent>
                    </div>
                    <div>
                      <InfoLabel>Date de création</InfoLabel>
                      <InfoContent>
                        {formatDate(selectedRecipe?.CreatedAt)}
                      </InfoContent>
                    </div>
                  </Column>
                  <Column style={{ paddingLeft: "20px" }}>
                    <div>
                      <InfoLabel>Date de dernière mise à jour</InfoLabel>
                      <InfoContent>
                        {formatDate(selectedRecipe?.UpdatedAt)}
                      </InfoContent>
                    </div>
                  </Column>
                </Grid>
              </Pane>
              <ManageRecipesListVersions />
            </>
          )}
        </>
      )}
    </Column>
  );
}
