import { create } from "zustand";
import { API_BASE_URL } from ".";
import type { User, UserStore } from "./types";
import type { Pagination } from "../types/definitions";
import { type APIResponse } from "../types/definitions";
import { normalizeError } from "../helpers/normalize-error";
import { isRight, left, right } from "fp-ts/lib/Either";
import apiClient from "../axios";

export interface IFetchUsersParams extends Pagination {
  email?: string;
}

export function fetchUsers({
  pageNumber,
  pageSize,
  email,
}: IFetchUsersParams): APIResponse<Array<User>> {
  const params: Record<string, any> | URLSearchParams | undefined = {
    PageSize: pageSize,
    PageNumber: pageNumber,
  };

  if (email !== undefined && email !== "") {
    params.Email = email;
  }

  return apiClient
    .get(`${API_BASE_URL}/users`, { params })
    .then((result) => right(result.data))
    .catch((err) => left(normalizeError(err)));
}

export const useUsersStore = create<UserStore>()((set) => ({
  users: [],
  errorMsg: "",
  loadUsers: async (params: IFetchUsersParams) => {
    const response = await fetchUsers(params);
    if (isRight(response)) {
      return set((state) => ({
        ...state,
        users: response.right.Data ?? [],
        errorMsg: "",
      }));
    }

    return set((state) => ({ ...state, errorMsg: response.left.message }));
  },
  updateUser: async (value: User) => {
    return;
  },
}));
