import { Icon } from "@iconify/react";
import {
  ErrorAlert,
  InfoAlert,
} from "../../../../components/Alerts/alerts.styles";
import { PrimaryButton } from "../../../../components/Buttons/buttons.styles";
import {
  InputIconWrapper,
  Input,
} from "../../../../components/Input/input.styles";
import { Table } from "../../../../components/Table/table.styles";
import { Title } from "../../../../components/Title/title.styles";
import { Column, FlexBox } from "../../../../components/Utils/flexbox.styles";
import { useNavigate } from "react-router-dom";
import { useTreatmentsStore } from "../../../../store/treatments";
import { useState, useCallback, useEffect } from "react";
import { DEFAULT_PAGE_SIZE } from "../../../../utils/constants";
import _debounce from "lodash/debounce";
import { Pagination } from "../../../../components/Pagination/pagination";
import { Loader } from "../../../../components/Utils/loader";
import { formatDate } from "../../../../utils/dates";

export default function ManageTreatmentsIndex() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { loadTreatments, treatments, errorMsg, totalPages } =
    useTreatmentsStore();

  const [currentPage, setCurrentPage] = useState<number>(1);

  const [searchInput, setSearchInput] = useState("");

  const fetchTreatments = useCallback(
    (nameInput: string) => {
      setIsLoading(true);
      loadTreatments({
        name: nameInput,
        pageNumber: currentPage,
        pageSize: DEFAULT_PAGE_SIZE,
      }).then(() => setIsLoading(false));
    },
    [currentPage, loadTreatments],
  );

  useEffect(() => {
    fetchTreatments(searchInput);
  }, [fetchTreatments]);

  const debounceFn = useCallback(
    _debounce((nameInput: string) => fetchTreatments(nameInput), 300),
    [fetchTreatments],
  );

  const handleSearchInput = (newInput: string) => {
    setSearchInput(newInput);
    debounceFn(newInput);
  };
  return (
    <Column>
      <Title>Gestion des traitements</Title>
      <InfoAlert>
        <span>
          <Icon icon="ic:twotone-info" />
          Vous pouvez au travers de ce panel gérer l&apos;ensemble des
          utilisateurs inscris sur OncAdvice.
        </span>
      </InfoAlert>
      <FlexBox className="aligned">
        <InputIconWrapper>
          <div className="icon">
            <Icon icon="ph:magnifying-glass-duotone" />
          </div>
          <Input
            value={searchInput}
            onChange={(e) => handleSearchInput(e.target.value)}
            placeholder="Rechercher par nom"
          />
        </InputIconWrapper>
        <PrimaryButton onClick={() => navigate("/manage-treatments/add")}>
          Ajouter
        </PrimaryButton>
      </FlexBox>
      {errorMsg.length > 0 && (
        <ErrorAlert>
          <span>
            <Icon icon="ic:twotone-error" />
            {errorMsg}
          </span>
        </ErrorAlert>
      )}
      <Table>
        <thead>
          <tr>
            <th>#</th>
            <th>Nom</th>
            <th>Date de création</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={4}>
                <Loader state={isLoading} />
              </td>
            </tr>
          ) : (
            <>
              {treatments && treatments.length > 0 ? (
                treatments.map((treatment, index) => (
                  <tr key={treatment.ID}>
                    <td>{index + 1}</td>
                    <td>{treatment.Name}</td>
                    <td>{formatDate(treatment?.CreatedAt)}</td>
                    <td>
                      <FlexBox className="horizontal-aligned">
                        <PrimaryButton
                          onClick={() =>
                            navigate(
                              `/manage-treatments/details/${treatment.ID}`,
                            )
                          }
                        >
                          Voir
                        </PrimaryButton>
                      </FlexBox>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td style={{ textAlign: "center" }} colSpan={4}>
                    Aucun résultat
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </Table>
      <Pagination
        totalPages={totalPages}
        page={currentPage}
        onChange={setCurrentPage}
      />
    </Column>
  );
}
