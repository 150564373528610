import styled from "styled-components";

export const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;

  th {
    text-align: left;
    background-color: #19181d;
    padding: 5px 10px;
  }

  td {
    padding: 10px;
  }

  tr {
    background-color: #2b2a2f;
    border-bottom: 1px solid var(--onc-grey);
  }

  tr:hover {
    background-color: #403f43;
  }
`;
