import { useEffect, useState } from "react";
import { PrimaryButton } from "../../../../components/Buttons/buttons.styles";
import { Input } from "../../../../components/Input/input.styles";
import { Pane } from "../../../../components/Pane/pane.styles";
import { SubTitle, Title } from "../../../../components/Title/title.styles";
import {
  Column,
  FormGroup,
  Grid,
} from "../../../../components/Utils/flexbox.styles";
import { InfoContent, InfoLabel } from "./account.styles";
import { useAccountStore } from "../../../../store/account";
import { Loader } from "../../../../components/Utils/loader";
import {
  ErrorMessages,
  SuccessMessage,
} from "../../../../components/Alerts/alerts";
import type { ZodIssue } from "zod";
import { z, ZodError } from "zod";
import type { ValidationErrors } from "../../../../types/definitions";
import { SHA256 } from "crypto-js";
import { formatDate } from "../../../../utils/dates";

export default function Account() {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { fetchAccount, errorMsg, user, updatePassword, successMsg } =
    useAccountStore();

  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");

  const [errorsLists, setErrorsList] = useState<ValidationErrors>({});

  useEffect(() => {
    fetchAccount().then(() => setIsLoading(false));
  }, [fetchAccount]);

  const onUpdatePassword = async () => {
    setErrorsList({});
    const validation = z
      .object({
        oldPassword: z.string().min(1, {
          message: "Le champs ancien mot de passe est requis.",
        }),
        newPassword: z.string().min(1, {
          message: "Le champs nouveau mot de passe est requis.",
        }),
        confirmNewPassword: z.string().min(1, {
          message: "Le champs confirmer votre nouveau mot de passe est requis.",
        }),
      })
      .refine((schema) => {
        return !(schema.newPassword === schema.oldPassword);
      }, "Le nouveau mot de passe n'est pas le même que celui présent dans la confirmation.");

    try {
      validation.parse({
        newPassword,
        confirmNewPassword,
        oldPassword,
      });
      setIsLoading(true);
      await updatePassword(
        SHA256(oldPassword).toString(),
        SHA256(newPassword).toString(),
      );
      setOldPassword("");
      setNewPassword("");
      setConfirmNewPassword("");
    } catch (error) {
      if (error instanceof ZodError) {
        setErrorsList(
          (error as ZodError).errors.reduce(
            (a, value: ZodIssue) => ({
              ...a,
              [value.path[0]]: value.message,
            }),
            {},
          ),
        );
      } else {
        setErrorsList({ default: "Une erreur inattendue est survenue." });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Column>
      <Title>Mon compte</Title>
      {errorMsg && <ErrorMessages errorsList={errorMsg} />}
      {successMsg && <SuccessMessage successMsg={successMsg} />}
      {isLoading ? (
        <Loader state={isLoading} />
      ) : (
        <>
          <Pane>
            <SubTitle>Modifier mon mot de passe</SubTitle>
            {errorsLists && <ErrorMessages errorsList={errorsLists} />}
            <FormGroup>
              <span>Mot de passe précédent:</span>
              <Input
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                isError={
                  errorsLists && errorsLists["oldPassword"] !== undefined
                }
                placeholder="******"
                type="password"
              />
            </FormGroup>
            <FormGroup>
              <span>Nouveau mot de passe:</span>
              <Input
                value={newPassword}
                isError={
                  errorsLists && errorsLists["newPassword"] !== undefined
                }
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="******"
                type="password"
              />
            </FormGroup>
            <FormGroup>
              <span>Confirmer le nouveau mot de passe:</span>
              <Input
                value={confirmNewPassword}
                isError={
                  errorsLists && errorsLists["confirmNewPassword"] !== undefined
                }
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                placeholder="******"
                type="password"
              />
            </FormGroup>
            <FormGroup>
              <PrimaryButton
                onClick={onUpdatePassword}
                disabled={isLoading || errorMsg.length > 0}
              >
                Confirmer
              </PrimaryButton>
            </FormGroup>
          </Pane>
          <Pane>
            <SubTitle>Mes informations</SubTitle>
            <Grid>
              <Column
                style={{ borderRight: "1px solid grey", paddingRight: "20px" }}
              >
                <div>
                  <InfoLabel>Nom</InfoLabel>
                  <InfoContent>{user?.Lastname}</InfoContent>
                </div>
                <div>
                  <InfoLabel>Prénom</InfoLabel>
                  <InfoContent>{user?.Firstname}</InfoContent>
                </div>
              </Column>
              <Column style={{ paddingLeft: "20px" }}>
                <div>
                  <InfoLabel>Email</InfoLabel>
                  <InfoContent>{user?.Email}</InfoContent>
                </div>
                <div>
                  <InfoLabel>Date de naissance</InfoLabel>
                  <InfoContent>{formatDate(user?.BirthDate)}</InfoContent>
                </div>
              </Column>
            </Grid>
          </Pane>
        </>
      )}
    </Column>
  );
}
