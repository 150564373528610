import Markdown from "react-markdown";
import { Pane } from "../../../../../components/Pane/pane.styles";
import { Title } from "../../../../../components/Title/title.styles";
import { Column } from "../../../../../components/Utils/flexbox.styles";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useRecipesStore } from "../../../../../store/recipes";
import { ErrorMessages } from "../../../../../components/Alerts/alerts";
import { Loader } from "../../../../../components/Utils/loader";

export function ManageRecipesDetailsContent() {
  const { id } = useParams();

  const { fetchRecipeContent, recipeContent, errorMsg } = useRecipesStore();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (id) {
      fetchRecipeContent(id).then(() => {
        setIsLoading(false);
      });
    }
  }, []);

  return (
    <Column>
      <Title>Contenu</Title>
      <Pane>
        {errorMsg && <ErrorMessages errorsList={errorMsg} />}
        {isLoading ? (
          <Loader state={isLoading} />
        ) : (
          <Markdown>{recipeContent?.file_content}</Markdown>
        )}
      </Pane>
    </Column>
  );
}
